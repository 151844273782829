import { Components, Theme } from '@mui/material';
import { getBackgroundColor } from '../../util';

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      textTransform: 'none',
      fontSize: theme.typography.body2.fontSize,
      minWidth: 'max-content',
      textWrap: 'nowrap',

      '&.Mui-selected': {
        color: theme.palette.text.primary,
        backgroundColor: getBackgroundColor(theme, ownerState.color),
      },
    }),
  },
};
