import type { PaletteColor, ColorProp } from '../types';
import type { Theme } from '../theme';

type Color = ColorProp | 'default' | 'standard';

export const getColorTheme = (
  theme: Theme,
  color: Color | undefined,
  prop: PaletteColor,
) => {
  const colorKey = (
    color && !['inherit', 'default', 'standard'].includes(color)
      ? color
      : 'primary'
  ) as Exclude<ColorProp, 'inherit'>;
  return theme.palette[colorKey][prop];
};

export const getMainColor = (theme: Theme, color?: Color) =>
  getColorTheme(theme, color, 'main');

export const getContrastColor = (theme: Theme, color?: Color) =>
  getColorTheme(theme, color, 'contrastText');

export const getBackgroundColor = (theme: Theme, color?: Color) =>
  getColorTheme(theme, color, 'background');
