import { Components, Theme } from '@mui/material';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    groupLabel: ({ theme }) => {
      return {
        // don't add padding for "empty" group labels
        ':not(:empty)': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
      };
    },
  },
};
