import { gql } from '@apollo/client';

export const GET_PUBNUB_CONFIG = gql`
  query getPubnubConfig {
    pubnub {
      userId
      userParticipantId
      pamToken
      channels
      channelGroups
      pushChannels
      browserPushChannels
      subscribeKey
      publishKey
      deviceToken
    }
  }
`;
