import { gql } from '@apollo/client';
import { APPOINTMENT_AGENT_SETTINGS_ACTIVE_INTEGRATION_FIELDS } from '../../fragments/gql/appointmentFragment.gql';

export const UPDATE_APPOINTMENT_AGENT_SETTINGS = gql`
  mutation updateAppointmentAgentSettings(
    $input: UpdateAppointmentAgentSettingsInput!
    $first: Int = 35
    $after: String
  ) {
    updateAppointmentAgentSettings(input: $input) {
      appointmentAgentSettings {
        __typename
        id
        name
        settings
        agentProfile
        optionalPrompts
        overallEmphasis
        numaInCallFlow
        webSchedulerReliability
        textingCanWeTextSpeech
        textingOnYesSpeechCanWeText
        textingOnNoSpeechCanWeText
        textingMessageToSend
        textingHangupRecovery
        renderedTextingMessageToSend
        renderedTextingHangupRecovery
        activeIntegrationId
        minimumLeadTimeInMinutes
        maximumBookingHorizonInDays
        enableHangupRecovery
        activeIntegration {
          ...AppointmentAgentSettingsActiveIntegrationFields
        }
      }
    }
  }
  ${APPOINTMENT_AGENT_SETTINGS_ACTIVE_INTEGRATION_FIELDS}
`;
