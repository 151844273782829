import { Components, Theme } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  defaultProps: {},
  styleOverrides: {
    // This reverts a workaround from Mui which works well if the color has some alpha
    // but not if it's solid. Then again it works only with certain colors.
    // In dark mode, the divider is set to a solid color, so we do not need their workaround
    // which makes the border almost invisible. The undefined value is used to get the default
    // behavior.
    //
    // eslint-disable-next-line max-len
    // (https://github.com/mui/material-ui/blob/24e561c3156875fa3f7aca85e17a91c05a5fd28d/packages/material-ui/src/TableCell/TableCell.js#L17-L24)
    root: ({ theme }) => ({
      borderBottom:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.divider}`
          : undefined,
    }),
  },
};
