// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';
import { delay, eventChannel } from 'redux-saga';
import {
  all,
  fork,
  take,
  call,
  select,
  takeEvery,
  put,
} from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'redux-first-history';

import { getUserId } from './auth';
import type { WebReducerState } from '../reducers/types';

import { isAutotestUser } from './users';

// Actions

type UpdateIntercomStatusAction = {
  type: 'numbox-web/intercom/UPDATE_STATUS';
  payload: {
    isBooted: boolean;
  };
};

const updateIntercomStatus = (
  isBooted: boolean,
): UpdateIntercomStatusAction => ({
  type: 'numbox-web/intercom/UPDATE_STATUS',
  payload: {
    isBooted,
  },
});

type UpdateIntercomUnreadCountAction = {
  type: 'numbox-web/intercom/UPDATE_UNREAD_COUNT';
  payload: {
    unreadCount: number;
  };
};

const updateIntercomUnreadCount = (
  unreadCount = 0,
): UpdateIntercomUnreadCountAction => ({
  type: 'numbox-web/intercom/UPDATE_UNREAD_COUNT',
  payload: {
    unreadCount,
  },
});

type ShowIntercomConversationAction = {
  type: 'numbox-web/intercom/SHOW_INTERCOM_CONVERSATION';
  payload: {};
};

export const showIntercomConversation = (): ShowIntercomConversationAction => ({
  type: 'numbox-web/intercom/SHOW_INTERCOM_CONVERSATION',
  payload: {},
});

type KNOWN_TOURS = 'AUTOPILOT';
type StartIntercomTour = {
  type: 'numbox-web/intercom/START_TOUR';
  payload: {
    tour: KNOWN_TOURS;
  };
};

export const startIntercomTour = (tour: KNOWN_TOURS): StartIntercomTour => ({
  type: 'numbox-web/intercom/START_TOUR',
  payload: {
    tour,
  },
});

// Reducer

export type IntercomReducerState = {
  isBooted: boolean;
  unreadCount: number | null | undefined;
};

export const INTERCOM_INITIAL_STATE = {
  isBooted: false,
  unreadCount: -1,
};

type IntercomReducerActions =
  | UpdateIntercomStatusAction
  | UpdateIntercomUnreadCountAction
  | {
      type: 'numbox-web/intercom/RESET_INTERCOM';
    };

const IntercomReducer = (
  state: IntercomReducerState = INTERCOM_INITIAL_STATE,
  action: IntercomReducerActions,
) => {
  switch (action.type) {
    case 'numbox-web/intercom/RESET_INTERCOM': {
      return INTERCOM_INITIAL_STATE;
    }
    case 'numbox-web/intercom/UPDATE_STATUS': {
      const { isBooted } = action.payload;
      return {
        ...state,
        isBooted,
      };
    }
    case 'numbox-web/intercom/UPDATE_UNREAD_COUNT': {
      const { unreadCount } = action.payload;
      return {
        ...state,
        unreadCount,
      };
    }
    default:
      return state;
  }
};

// Selectors

export const getIsIntercomReady = (state: WebReducerState) =>
  state.intercom.isBooted;

export const getIntercomUnreadCount = (state: WebReducerState) =>
  state.intercom.unreadCount;

// Sagas

declare global {
  interface Window {
    Intercom: any;
  }
}

function* onShowIntercom(): Generator<any, void, void> {
  try {
    if (window.Intercom && window.Intercom.booted) {
      yield call(window.Intercom, 'show');
    }
  } catch (error) {
    console.warn(error);
  }
}

function* handleUnreadCountChanged() {
  const channel = eventChannel(emitter => {
    window.Intercom('onUnreadCountChange', (unreadCount: number) => {
      emitter({ unreadCount });
    });

    const unsubscribeFromChannel = () =>
      window.Intercom('onUnreadCountChange', () => {});
    return unsubscribeFromChannel;
  });
  while (true) {
    const { unreadCount } = yield take(channel);
    yield put(updateIntercomUnreadCount(unreadCount));
  }
}

const DEV_INTERCOM_APP_ID = 'lfmafixv';

const getIntercomAppId = () =>
  import.meta.env.VITE_INTERCOM_ID || DEV_INTERCOM_APP_ID;

function* waitForIntercomBoot() {
  const MAX_REGISTRATION_CHECKS = 5;
  const DELAY_BETWEEN_CHECKS = 1000;

  let curTry = 0;
  while (curTry < MAX_REGISTRATION_CHECKS) {
    if (window.Intercom && window.Intercom.booted) {
      return true;
    }
    yield delay(DELAY_BETWEEN_CHECKS);
    curTry += 1;
  }

  return false;
}

function* onRegisterIntercom(): Generator<any, any, any> {
  try {
    if (!window.Intercom) {
      return;
    }

    if (yield select(isAutotestUser)) {
      return;
    }

    const userId = yield select(getUserId);

    yield call(window.Intercom, 'boot', {
      app_id: getIntercomAppId(),
      user_id: userId,
      alignment: 'right',
      horizontalPadding: 0,
      verticalPadding: 0,
      hide_default_launcher: true,
    });

    if (yield call(waitForIntercomBoot)) {
      yield put(updateIntercomStatus(true));
      yield fork(handleUnreadCountChanged);
    }
  } catch (error) {
    console.warn(error);
  }
}

function* onShutdownIntercom(): Generator<any, void, void> {
  try {
    if (window.Intercom && window.Intercom.booted) {
      yield call(window.Intercom, 'shutdown');
    }
  } catch (error) {
    console.warn(error);
  }
}

function* onRouteChange(): Generator<any, void, void> {
  try {
    if (window.Intercom && window.Intercom.booted) {
      yield call(window.Intercom, 'update', {
        last_request_at: new Date().getTime() / 1000,
      });
    }
  } catch (error) {
    console.warn(error);
  }
}

const TOUR_IDS: Record<KNOWN_TOURS, number> = {
  AUTOPILOT: !import.meta.env.PROD ? 277547 : 100434,
};

function* onStartTour(action: StartIntercomTour) {
  try {
    if (window.Intercom && window.Intercom.booted) {
      yield call(window.Intercom, 'startTour', TOUR_IDS[action.payload.tour]);
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* watchIntercom(): Saga<any> {
  yield all([
    takeEvery('numbox-web/intercom/SHOW_INTERCOM_CONVERSATION', onShowIntercom),
    takeEvery('AUTH_USER.SUCCESS', onRegisterIntercom),
    takeEvery('STORE_AUTH0_TOKEN', onRegisterIntercom),
    takeEvery('REFRESH_AUTH0_TOKEN', onRegisterIntercom),
    takeEvery('VERIFY_USER.SUCCESS', onRegisterIntercom),
    takeEvery('REFRESH_TOKEN.SUCCESS', onRegisterIntercom),
    takeEvery('UPDATE_PASSWORD.SUCCESS', onRegisterIntercom),
    takeEvery('LOGOUT_USER.SUCCESS', onShutdownIntercom),
    takeEvery(LOCATION_CHANGE, onRouteChange),
    takeEvery('numbox-web/intercom/START_TOUR', onStartTour),
  ]);
}

export default IntercomReducer;
