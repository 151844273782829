import { Components, Theme } from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  defaultProps: {},
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      // Remove background-image: var(--Paper-overlay) that dilutes colors
      // which is a problem in dark mode as paper is not white
      backgroundImage: 'none',
    }),
  },
};
