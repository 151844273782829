import { gql } from '@apollo/client';

export const QualificationSurveyFragment = gql`
  fragment QualificationSurveyFields on QualificationSurveyQL {
    id
    buyoutAmountRange
    condition
    currentLoanAmountRange
    interestedInTrading
    leaseRemainingTimeRange
    ownershipStatus
    score
    equityTradeOpportunity {
      id
      stage
      status
      closedByParticipant {
        id
        displayName
      }
    }
  }
`;
