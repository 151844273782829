import { gql } from '@apollo/client';

export const DISCARD_DISRUPTION_VOICE_SCRIPT = gql`
  mutation discardDisruptionVoiceScript(
    $input: DiscardDisruptionVoiceScriptInput!
  ) {
    discardDisruptionVoiceScript(input: $input) {
      place {
        id
        answeringMode
        answeringModeUntil
        disruptions {
          id
          disruptionDepartmentVoiceScriptId
          draftDisruptionDepartmentVoiceScriptId
          holidayDepartmentVoiceScriptId
          draftHolidayDepartmentVoiceScriptId
        }
      }
    }
  }
`;
