import { gql } from '@apollo/client';

export const GET_ACTIVE_INTEGRATIONS = gql`
  query getActiveIntegrations($placeId: String!) {
    knownIntegrationCategories
    availableIntegrations {
      id
      name
      description
      categories
      setupType
      settingsUrl
      featureFlag
      internalId
      configurationSchema
    }
    activeIntegrations(placeId: $placeId) {
      id
      integrationId
      status
      friendlyName
      configuration
    }
  }
`;
