import { useMutation, useQuery } from '@apollo/client';
import { gql } from '../gql-tag';

export const etoSettingsFragment = gql(`
  fragment ETOSettings on ETOSettingsQL {
    id
    placeId
    noRepeat
    oneAtATime
    daysFrequency
    eligibilityCriteria
    isInboxLabelEnabled
    minimumQualifyingScore
    automationGroup {
      automations {
        ...AutomationFields
      }
    }
  }
`);

export const getETOSettingsQuery = gql(`
  query getETOSettings($placeId: String!) {
    etoSettings(placeId: $placeId) {
      ...ETOSettings
    }
  }
`);

export const updateETOSettingsMutation = gql(`
  mutation updateETOSettings($input: UpdateETOSettingsInput!) {
    updateEtoSettings(input: $input) {
      etoSettings {
        ...ETOSettings
      }
    }
  }
`);

export const setupEquityTradeOpportunityCampaignMutation = gql(`
  mutation setupEquityTradeOpportunityCampaign($input: SetupEquityTradeOpportunityCampaignInput!) {
    setupEquityTradeOpportunityCampaign(input: $input) {
      etoSettings {
        ...ETOSettings
      }
    }
  }
`);

export const useGetETOSettings = (placeId: string) => {
  return useQuery(getETOSettingsQuery, {
    variables: { placeId },
  });
};

export const useUpdateETOSettings = () => {
  return useMutation(updateETOSettingsMutation, {
    update(cache, { data }) {
      const updatedSettings = data?.updateEtoSettings?.etoSettings;
      if (updatedSettings) {
        // Update the getETOSettings query in the cache
        cache.writeQuery({
          query: getETOSettingsQuery,
          variables: { placeId: updatedSettings.placeId },
          data: {
            etoSettings: updatedSettings,
          },
        });
      }
    },
  });
};
export const useSetupEquityTradeOpportunityCampaign = () => {
  return useMutation(setupEquityTradeOpportunityCampaignMutation);
};
