import { createSelector } from 'reselect';
import type { WebReducerState } from '../reducers/types';

// Action Types
const INCREMENT_ACTIVE_BANNERS = 'BANNERS/INCREMENT_ACTIVE_BANNERS';
const DECREMENT_ACTIVE_BANNERS = 'BANNERS/DECREMENT_ACTIVE_BANNERS';

// State type
export type BannersReducerState = {
  activeBannerCount: number;
};

// Initial state
const INITIAL_STATE: BannersReducerState = {
  activeBannerCount: 0,
};

// Action creators
export const incrementActiveBanners = () => ({
  type: INCREMENT_ACTIVE_BANNERS,
});

export const decrementActiveBanners = () => ({
  type: DECREMENT_ACTIVE_BANNERS,
});

// Type for actions
type BannersAction =
  | { type: typeof INCREMENT_ACTIVE_BANNERS }
  | { type: typeof DECREMENT_ACTIVE_BANNERS };

// Reducer
const BannersReducer = (
  state: BannersReducerState = INITIAL_STATE,
  action: BannersAction,
): BannersReducerState => {
  switch (action.type) {
    case INCREMENT_ACTIVE_BANNERS:
      return {
        ...state,
        activeBannerCount: state.activeBannerCount + 1,
      };

    case DECREMENT_ACTIVE_BANNERS:
      return {
        ...state,
        activeBannerCount: Math.max(0, state.activeBannerCount - 1),
      };

    default:
      return state;
  }
};

// Selectors
export const getActiveBannerCount = (state: WebReducerState) =>
  state.banners.activeBannerCount;

export const hasActiveBanners = createSelector(
  [getActiveBannerCount],
  (count: number) => count > 0,
);

export default BannersReducer;
