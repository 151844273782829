import type { AxiosPromise, AxiosError } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import get from 'lodash/get';
import jwt_decode from 'jwt-decode';
import type { JwtPayload } from 'jwt-decode';

import { getAccessToken, getRefreshToken } from '../refreshToken';
import { getAuth0LoginFunction } from '../auth0';
import refreshToken from './refreshToken';

const _ = { get };

export const isAuthException = (error: AxiosError<any>) =>
  // eslint-disable-next-line eqeqeq
  _.get(error, 'response.status') == 401 || error.code == '401';

const isBlacklistedRefreshURL = (exception: AxiosError<any>) =>
  exception?.config?.url?.includes('/device_token') ?? false;

const isAuthRequest = (exception: AxiosError<any>) =>
  exception?.config?.url?.includes('/token') ?? false;
const RETRY_COUNT = 2;

const auth0Domains = [
  'https://dev-fb5jk1uw4c7wq8zg.us.auth0.com/',
  'https://login.numa.com/',
];

export const testIsAuth0Token = (token: string | null | undefined): boolean => {
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwt_decode<JwtPayload>(token);
    const issuer = decodedToken?.iss;
    if (!issuer) {
      return false;
    }

    return auth0Domains.includes(issuer);
  } catch {
    return false;
  }
};

export type Meta = {
  onSuccess?: () => void;
  onFailure?: (arg0: AxiosError<any>) => void;
};

/*
 * tweaked from HackerNoon Article "maximum benefit from fewest key strokes"
 * https://goo.gl/2othTQ
 *
 * For additional clarity read about flow's $Call type
 * and this example from the flow commit that deprecated the Existential Type
 * https://goo.gl/MiKnv7
 */
export type ExtractReturn<Fn extends (...args: any) => any> = ReturnType<Fn>;

export type ApiAction<T, P, M> = {
  type: T;
  payload: P;
  meta: M | null | undefined;
};

export type ApiRequest<T, Params, M> = (
  arg0: Params,
  arg1: M | null | undefined,
) => ApiAction<T, Params, M>;
export type ApiResponse<ResponseType, Response, M> = (
  arg0: Response,
  arg1: M | null | undefined,
) => ApiAction<ResponseType, Response, M>;
export type ApiError<M> = (arg0: any, arg1: M | null | undefined) => any;
export type ApiActionCreators<RequestType, Params, ResponseType, Response, M> =
  {
    request: ApiRequest<RequestType, Params, M>;
    success: ApiResponse<ResponseType, Response, M>;
    error: ApiError<M>;
  };

/**
 *
 * Call an API and dispatch success/failure outcomes based on the outcome.
 *
 * @param {any} api - a Promise that calls out to an external API.
 *                    Promise expected to take an http client and an optional params object.
 * @param {any} actions - an object with the form. modules/actionCreators#createActionCreator
 *                        provided for ease of action creation.
 *                        {
 *                          request: (params) => action,
 *                          success: (params) => action,
 *                          error: (params) => action,
 *                        }
 * @param {any} params - an object of params that will be used in the API
 * @param {any} [meta={}] - optional meta parameters to persist through success/failure actions
 * @returns
 */
function* callApi<RequestType, Params, ResponseType, Response, M>( // eslint-disable-line no-shadow
  api: (arg0: Params) => AxiosPromise<Response>,
  actions: ApiActionCreators<RequestType, Params, ResponseType, Response, M>,
  params: Params,
  meta: M | null | undefined,
): Generator<any, Response | null | undefined, any> {
  let callException;
  for (let i = 0; i < RETRY_COUNT; i += 1) {
    try {
      const response = yield call(api, params);
      let result = null;
      if (meta) {
        result = yield put(actions.success(response.data, meta));
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onSuccess' does not exist on type 'M'.
        if (meta.onSuccess && typeof meta.onSuccess === 'function') {
          // eslint-disable-next-line max-len
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onSuccess' does not exist on type 'M'.
          yield call(meta.onSuccess, response.data);
        }
      } else {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        result = yield put(actions.success(response.data));
      }
      return result;
    } catch (exception) {
      callException = exception as AxiosError<any>;

      if (!isAuthException(callException) || isAuthRequest(callException)) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFailure' does not exist on type 'M'.
        if (meta && meta.onFailure && typeof meta.onFailure === 'function') {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFailure' does not exist on type 'M'.
          yield call(meta.onFailure, exception);
        }

        return yield put(actions.error(exception, meta));
      }
      if (!isBlacklistedRefreshURL(callException)) {
        const accessToken = yield select(getAccessToken);
        const token = yield select(getRefreshToken);

        const isAuth0Token = testIsAuth0Token(accessToken);

        let reauthResponse;
        if (isAuth0Token) {
          const auth0Login = yield select(getAuth0LoginFunction);
          reauthResponse = yield call(
            () =>
              new Promise(async resolve => {
                auth0Login({
                  storeToken: false,
                  cacheMode: 'off',
                  onSuccess: (
                    newAccessToken: string,
                    newRefreshToken: string | undefined,
                    userData: any,
                  ) => {
                    resolve({
                      accessToken: newAccessToken,
                      refreshToken: newRefreshToken,
                      userData,
                    });
                  },
                  onFailure: () => {
                    resolve({ error: "Couldn't reauth with Auth0" });
                  },
                });
              }),
          );
        } else {
          reauthResponse = yield call(refreshToken, token);
        }

        if (isAuth0Token && reauthResponse?.accessToken) {
          yield put({
            type: 'REFRESH_AUTH0_TOKEN',
            payload: {
              ...reauthResponse.userData,
              auth: {
                ...reauthResponse.userData.auth,
                access_token: reauthResponse.accessToken,
                refresh_token:
                  reauthResponse.refreshToken || reauthResponse.accessToken,
              },
            },
          });
        }

        if (reauthResponse.error) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFailure' does not exist on type 'M'.
          if (meta?.onFailure && typeof meta.onFailure === 'function') {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFailure' does not exist on type 'M'.
            yield call(meta.onFailure, callException);
          }
          return yield put(actions.error(callException, meta));
        }
      }
      yield call(delay, 1000);
    }
  }
  return yield put(actions.error(callException, meta));
}

export function* serviceCall<RequestType, Params, ResponseType, Response, M>( //eslint-disable-line
  api: (arg0: Params) => AxiosPromise<Response>,
  actions: ApiActionCreators<RequestType, Params, ResponseType, Response, M>,
  params: Params,
  meta: M | null | undefined,
): Generator<any, void, void> {
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  yield call(callApi, api, actions, params, meta);
}

export default callApi;
