import type { Components, Theme } from '@mui/material';

export const MuiPopper: Components<Theme>['MuiPopper'] = {
  defaultProps: {
    // this is so stories can target popper elements from within the
    // recommended `canvasElement`, instead of the default `body`
    ...(import.meta.env.STORYBOOK === 'true' && {
      container: document.getElementById('storybook-root'),
    }),
  },
};
