import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

export type CircularProgressProps = Pick<
  MuiCircularProgressProps,
  'value' | 'variant' | 'color' | 'size'
>;

export const CircularProgress: FC<PropsWithChildren<CircularProgressProps>> = ({
  children,
  ...rest
}) => {
  return <MuiCircularProgress {...rest}>{children}</MuiCircularProgress>;
};
