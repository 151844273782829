import includes from 'lodash/includes';
import head from 'lodash/head';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import type {
  ApiUser,
  ApiPlacePermissions,
  ApiAccountPermissions,
  ApiPossibleRoles,
} from '@numbox/services';

import { getRole } from '@numbox/react';
import type { ExtractReturn } from '@numbox/modules';
import { AuthModule, UserModule } from '@numbox/modules';
import type { WebReducerState } from '../reducers/types';
import type {
  StoreAuth0TokenAction,
  RefreshAuth0TokenAction,
} from '../reducers/numboxClient';

import { getPlaces } from '../selectors/place';
import { getPlaceById, getSelectedPlace } from './places';
import { UserRemovedFromPlaceAction } from '~/actions/inbox';

// Selectors
export const getCurrentUser = (
  state: WebReducerState,
): ApiUser | null | undefined => state.users.current;
export const getUserById = (
  state: WebReducerState,
  id: string,
): ApiUser | null | undefined => state.users.list.find(user => user.id === id);

export const getUserByIds = (
  state: WebReducerState,
  ids: Array<string>,
): Array<ApiUser> | null | undefined =>
  state.users.list.filter(user => ids.includes(user.id));

export const isAutotestUser = (state: WebReducerState) => {
  const user = getCurrentUser(state);
  return user && user.email.startsWith('autotest');
};

export const getUsers = (state: WebReducerState) => state.users.list;
export const getUsersAtPlace = (state: WebReducerState, placeId: string) =>
  state.users.list.filter(user => user.teams.find(team => team.id === placeId));

export const hasPlacePermission = (
  state: WebReducerState,
  needs: string,
  userId?: string,
  placeId?: string,
): boolean => {
  const user = userId ? getUserById(state, userId) : getCurrentUser(state);
  const place = placeId
    ? getPlaceById(state, placeId)
    : getSelectedPlace(state);
  if (!user || !place) return false;
  const placePermissions = user.place_permissions;
  if (includes(placePermissions[place.id], needs)) {
    return true;
  }
  return false;
};

export const getPotentialPlaces = createSelector([getPlaces], places => {
  if (!head(places)) {
    return [];
  }
  return places.map(place => ({
    id: place.id,
    name: place.name,
  }));
});

export const getRolePermissions = (state: WebReducerState) =>
  state.users.rolePermissions;

export const getRoleForUser = (
  state: WebReducerState,
  user: ApiUser | null | undefined,
): ApiPossibleRoles => {
  if (!user) {
    return 'CUSTOM';
  }

  return getRole({
    accountPermissions: user.account_permissions,
    placePermissions: user.place_permissions,
    rolePermissions: state.users.rolePermissions,
  });
};

export const getRoleForCurrentUser = (state: WebReducerState) => {
  const currentUser = getCurrentUser(state);
  return getRoleForUser(state, currentUser);
};

export const getHasExternalIdentityForCurrentUser = (
  state: WebReducerState,
) => {
  const currentUser = getCurrentUser(state);
  return currentUser?.has_external_identity ?? false;
};

export const getUserMembership = createSelector(
  [getUsers, getPlaces],
  (users, teams) => {
    if (!head(users) || !head(teams)) {
      return [];
    }

    const membershipList = users.map((u: ApiUser) => {
      const userTeams = u.teams.map(t => t.id);
      const userMembership = teams.map(team => ({
        id: team.id,
        name: team.name,
        isMember: userTeams.indexOf(team.id) !== -1,
      }));

      return {
        userId: u.id,
        teams: userMembership,
      };
    });

    return membershipList;
  },
);

// Reducer
export const USERS_INITIAL_STATE = {
  list: [],
  current: null,
  rolePermissions: {
    EMPLOYEE: {
      place: [],
      account: [],
    },
    MANAGER: {
      place: [],
      account: [],
    },
    OWNER: {
      place: [],
      account: [],
    },
    CUSTOM: {
      place: [],
      account: [],
    },
  },
} satisfies UserReducerState;

export type UserReducerState = {
  list: Array<ApiUser>;
  current: ApiUser | null | undefined;
  rolePermissions: Record<
    ApiPossibleRoles,
    {
      place: Array<ApiPlacePermissions>;
      account: Array<ApiAccountPermissions>;
    }
  >;
};

type UpdateUserSettingsAction = ReturnType<typeof updateCurrentUserSettings>;

export const updateCurrentUserSettings = (
  /**
   * only allow the user settings to be passed,
   * as we implement this action in more places we will add fields to the `Pick<>`
   */
  payload: Partial<Pick<ApiUser, 'default_view'>>,
) => {
  return {
    type: 'USER_SETTING_UPDATE',
    payload,
  } as const;
};

type UserReducerActions =
  | ExtractReturn<typeof UserModule.verifyUser.success>
  | ExtractReturn<typeof UserModule.addUser.success>
  | ExtractReturn<typeof UserModule.fetchUsers.success>
  | ExtractReturn<typeof UserModule.deleteUser.success>
  | ExtractReturn<typeof AuthModule.auth.success>
  | ExtractReturn<typeof AuthModule.updatePassword.success>
  | ExtractReturn<typeof AuthModule.refreshToken.success>
  | ExtractReturn<typeof UserModule.updateUser.success>
  | StoreAuth0TokenAction
  | RefreshAuth0TokenAction
  | UserRemovedFromPlaceAction
  | UpdateUserSettingsAction;

const UserReducer = (
  state: UserReducerState = USERS_INITIAL_STATE,
  action: UserReducerActions,
) => {
  switch (action.type) {
    case 'REFRESH_TOKEN.SUCCESS':
    case 'VERIFY_USER.SUCCESS':
    case 'UPDATE_PASSWORD.SUCCESS':
    case 'STORE_AUTH0_TOKEN':
    case 'REFRESH_AUTH0_TOKEN':
    case 'AUTH_USER.SUCCESS': {
      return {
        ...state,
        current: action.payload.user,
        rolePermissions: action.payload.permission_sets,
      };
    }
    case 'FETCH_USERS.SUCCESS': {
      const users = action.payload;
      return {
        ...state,
        list: users,
      };
    }
    case 'ADD_USER.SUCCESS':
      return {
        ...state,
        list: [...state.list, action.payload],
      };

    case 'DELETE_USER.SUCCESS':
    case 'UPDATE_USER.SUCCESS': {
      const updatedUser = action.payload;
      const newList: Array<ApiUser> = state.list.map((u: ApiUser) => {
        if (u.id === updatedUser.id) {
          return updatedUser;
        }
        return u;
      });
      return {
        ...state,
        list: newList,
      };
    }

    case 'USER_REMOVED_FROM_PLACE': {
      const { userId, placeIds, placePermissions } = action.payload;
      const newState = cloneDeep(state);
      const targetUser = newState.list.find(user => user.id === userId);

      if (!targetUser) {
        return state;
      }

      const targetTeams = targetUser.teams.filter(team =>
        placeIds.includes(team.id),
      );

      const updatedUser = {
        ...targetUser,
        teams: targetTeams,
        place_permissions: placePermissions,
      };

      newState.list = newState.list.map(user => {
        if (user.id === userId) {
          return updatedUser;
        }

        return user;
      });

      return newState;
    }
    case 'USER_SETTING_UPDATE': {
      const newState = cloneDeep(state);
      // only update `state.current` if it already exists
      if (newState.current) {
        newState.current = {
          ...newState.current,
          ...action.payload,
        };
      }
      return newState;
    }
    default:
      return state;
  }
};

export default UserReducer;
