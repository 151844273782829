import { gql } from '../../gql-tag';

export const autocompleteConversationFields = gql(`
  fragment AutocompleteConversationFields on ConversationQL{
    id
    lastUpdated
    participant {
      id
      address
      displayName
      displayNameConfidence
      avatarUrl
      source
      cnamContact {
        id
        firstName
        lastName
      }
    }
    place {
      id
      name
    }
  }
`);

export const AUTO_COMPLETE_CONVERSATIONS = gql(`
  query searchConversations(
    $search: String!
    $first: Int!
    $after: String
    $sort: [ConversationModelSortEnum]
  ) {
    autocompleteConversations(
      search: $search
      first: $first
      after: $after
      sort: $sort
    ) @connection(key: "autocompleteConversations", filter: []) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...AutocompleteConversationFields
        }
      }
    }
  }
`);
