import { gql } from '@apollo/client';

export const GET_PLACE_DISRUPTION_SETTINGS = gql`
  query getPlaceDisruptionSettings($placeId: String!) {
    place(id: $placeId) {
      id
      answeringMode
      answeringModeFrom
      answeringModeUntil
      disruptions {
        id
        disruptionsGreeting
        disruptionsActive
        disruptionDepartmentVoiceScriptId
        draftDisruptionDepartmentVoiceScriptId
        holidayDepartmentVoiceScriptId
        draftHolidayDepartmentVoiceScriptId
      }
    }
  }
`;
