import { gql } from '@apollo/client';

export const typeDefs = gql`
  input PubNubAttachmentType {
    id: String!
    message_id: String!
    attachment_type: String!
    url: String
    place_id: String
    file_name: String
    size_bytes: Int
    width: Int
    height: Int
    text: String
    language: String
    note: String
    place_info_id: String
    video_upload_link_id: String
    video_microsite_link_id: String
    data: String
    before: String
    after: String
  }
  input PubNubSenderModel {
    id: String!
    address: String!
    source: String!
    first_name: String
    last_name: String
    comment: String
    display_name: String!
    display_name_confidence: PubNubParticipantDataConfidence!
    avatar_url: String
    is_employee: Boolean!
  }
  input PubNubParticipantModel {
    id: String!
    address: String!
    source: String!
    first_name: String
    last_name: String
    comment: String
    display_name: String!
    display_name_confidence: PubNubParticipantDataConfidence!
    avatar_url: String
    is_employee: Boolean!
    spam_status: PubNubSpamStatus!
    crm_contacts: [PubNubCrmContactModel!]
    customerCount: Int!
    latestCustomer: PubNubCustomerModel
  }
  input PubNubCustomerModel {
    id: String!
    first_name: String
    last_name: String
    display_name: String!
    addresses: [PubNubAddressModel]
  }
  input PubNubAddressModel {
    id: String!
    medium_type: PubNubAddressMediumType
    address: String!
    is_primary: Boolean
    is_active: Boolean
    deleted_at: DateTime
    source_entity_id: String
    source_entity_type: PubNubAddressSourceEntityType
  }
  input PubNubUser {
    id: String!
    email: String
    first_name: String
    last_name: String
    full_name: String
  }

  input PubNubIntent {
    name: String!
    has_response: Boolean!
    possible_bot_action: String!
    id: String!
  }

  input PubNubEquityTradeOpportunityModel {
    id: String!
    stage: String!
  }

  enum PubNubMessageFormat {
    assignment
    media
    multichoice
    note
    system
    text
    voice
    yelp
    outboundproxy
    loggedcall
    rostarted
    rocompleted
    outboundconference
    dialogflowbot
    surveyanswer
  }
  enum PubNubDeliveryStatus {
    delivered
    failed
    inprogress
    sent
  }
  input PubNubMention {
    user_id: String!
    tag: String!
  }
  input PubNubReasonSentIntentModel {
    name: String!
    display_name: String!
  }
  input PubNubReasonSentModel {
    kind: String!
    name: String
    intent: PubNubReasonSentIntentModel
  }
  input PubNubOutboundProxyCallModel {
    id: String!
    endtime: DateTime
    call_status: String
    call_duration: Int
  }
  input PubNubInboundPhoneCallParticipantModel {
    id: String!
    display_name: String!
    address: String!
  }
  input PubNubInboundPhoneCallRoutedUserModel {
    id: String!
    display_name: String!
  }
  input PubNubInboundPhoneCallRecordingModel {
    id: String!
    url: String
  }
  enum PubNubInboundPhoneCallReturnCallsRoutingOutcome {
    USER_ANSWERED
    FALLBACK_TO_SCRIPT
  }
  enum PubNubTranscriptEventType {
    AGENT_STEP
    RECORDED_SPEECH
    RECORDING_PAUSED
    RECORDING_STARTED
    SPEECH
  }
  input PubNubTranscriptEventModel {
    id: String!
    type: PubNubTranscriptEventType!
    timestamp: DateTime!
    start: Float!
    end: Float!
    text: String!
    confidence: Float!
    speaker: Int!
    speaker_confidence: Float!
  }
  input PubNubTranscriptAnalysisSectionModel {
    id: String!
    name: String!
    timestamp: DateTime!
  }
  input PubNubTranscriptAnalysisScoreModel {
    id: String!
    name: String!
    rating: Float!
    description: String!
  }
  input PubNubTranscriptAnalysisModel {
    id: String!
    timestamp: DateTime
    call_type: String
    subject: String
    summary: String
    next_steps: String
    coaching_tip: String
    coaching_sample: String
    sections: [PubNubTranscriptAnalysisSectionModel!]
    scores: [PubNubTranscriptAnalysisScoreModel!]
  }
  input PubNubTranscriptModel {
    id: String!
    events: [PubNubTranscriptEventModel]
    analysis: PubNubTranscriptAnalysisModel
  }
  input PubNubInboundPhoneCallModel {
    id: String!
    endtime: DateTime
    start_time: DateTime!
    is_active: Boolean!
    call_duration: Int
    conversation_id: String!
    transcript: PubNubTranscriptModel
    has_return_calls_routing: Boolean!
    return_calls_routing_outcome: PubNubInboundPhoneCallReturnCallsRoutingOutcome
    return_calls_routed_to: String
    is_being_recorded: Boolean!
    return_calls_routed_user: PubNubInboundPhoneCallRoutedUserModel
    participant: PubNubInboundPhoneCallParticipantModel!
    redirected_from: PubNubInboundPhoneCallParticipantModel
    call_recording: PubNubInboundPhoneCallRecordingModel
  }

  enum PubNubAppointmentState {
    UNQUALIFIED
    QUALIFIED
    BOOKED
    INFERRED
    ACTIVE
    EXPIRED
    LOST
    COMPLETED
  }
  enum PubNubAppointmentEnrichedFrom {
    MANUAL
    VOICE_SCRIPT
    CDK
    DEALER_TRACK
    MESSAGE_INTENT
    IMPORTED
    APPOINTMENT_AGENT
    TEKION
  }
  input PubNubAppointmentModel {
    id: String!
    state: PubNubAppointmentState!
    enriched_from: PubNubAppointmentEnrichedFrom
    appointment_id: String
    appointment_date: DateTime
    external_service_advisor_id: String
    external_service_advisor_name: String
    created_on: DateTime!
    expires_at: DateTime
    dialog_flow_bot_data: PubNubDialogFlowBotDataModel
    numbox_agent_session: PubNubNumboxAgentSessionModel
    feedback_submitted: Boolean
  }
  input PubNubCnamContactModel {
    id: String!
    first_name: String
    last_name: String
  }
  input PubNubCdkContactModel {
    id: String!
    first_name: String
    last_name: String
    company_name: String
    email: String
    phone_number: String
  }
  input PubNubDealertrackContactModel {
    id: String!
    first_name: String
    last_name: String
    company_name: String
    email: String
    phone_number: String
  }
  input PubNubCrmContactModel {
    id: String!
    first_name: String
    last_name: String
    company_name: String
    email: String
    phone_number: String
    contact_type: String
  }
  enum PubNubRepairOrderType {
    MANUAL
    CDK
    DEALERTRACK
    TEKION
    PBS
    REYNOLDS
    DEALERBUILT
  }
  enum PubNubRepairOrderStatus {
    ACTIVE
    COMPLETE
  }

  input PubNubRepairOrderStatusItem {
    id: String!
    status: PubNubStatusItemStatus!
    last_update_time: DateTime
    color: PubNubStatusItemColor!
  }

  input PubNubRepairOrder {
    id: String!
    repair_order_type: PubNubRepairOrderType!
    status: PubNubRepairOrderStatus!
    created_on: DateTime!
    order_number: String!
    tag_number: String
    opened_time: String
    completed_time: DateTime
    status_item: PubNubRepairOrderStatusItem
    service_advisor_name: String
    dms_status: String
    transportation_flags: String
    last_dms_update: DateTime
    promised_time: DateTime
    vehicle_make_friendly: String
    vehicle_model_friendly: String
    vehicle_vin: String
    vehicle_year: Int
  }

  input PubNubQualificationSurvey {
    id: String!
    buyout_amount_range: String
    condition: String!
    ownership_status: String!
    current_loan_amount_range: String
    lease_remaining_time_range: String
    interested_in_trading: Boolean
    score: Int
    equity_trade_opportunity: PubNubQualificationSurveyOpportunityModel
  }

  enum PubNubQualificationSurveyOpportunityStage {
    EXPIRED
    FOLLOW_UP
    INELIGIBLE
    LOST
    PROSPECT
    QUALIFICATION_ANSWERED
    QUALIFIED
    UNQUALIFIED
    VOID
    WON
  }

  input PubNubQualificationSurveyOpportunityModel {
    id: String!
    stage: PubNubQualificationSurveyOpportunityStage!
    status: String
    closed_by_participant: PubNubQualificationSurveyOpportunityParticipantModel
  }

  input PubNubQualificationSurveyOpportunityParticipantModel {
    id: String!
    display_name: String!
  }

  input PubNubMessageModel {
    id: String!
    content: String!
    message_format: PubNubMessageFormat!
    attachments: [PubNubAttachmentType]!
    timestamp: DateTime!
    is_inbound: Boolean!
    sender: PubNubSenderModel!
    recipient: PubNubSenderModel!
    is_archived: Boolean!
    is_deleted: Boolean!
    is_suggestion: Boolean
    channel_id: String!
    conversation_id: String!
    read_status: DateTime
    title: String!
    team_id: String!
    reply_status: String
    responds_to_id: String
    thumb: String
    comment: String
    delivery_status: PubNubDeliveryStatus!
    delivery_error: Int
    can_teach_numa: Boolean
    intent_name: String
    intent_display_name: String
    intent_id: String
    mentions: [PubNubMention!]!
    reason_sent: PubNubReasonSentModel
    outbound_proxy_call: PubNubOutboundProxyCallModel
    outbound_conference_call: PubNubOutboundConferenceCallModel
    call: PubNubInboundPhoneCallModel
    logged_call_interaction: PubNubParticipantLoggedCallInteraction
    repair_order: PubNubRepairOrder
    payment_request: PubNubPaymentRequestModel
    dialog_flow_bot_data: PubNubDialogFlowBotDataModel
    numbox_agent_session: PubNubNumboxAgentSessionModel
    caps_lock_fixed: Boolean!
    is_heat_case: Boolean!
    qualification_survey: PubNubQualificationSurvey
  }

  input PubNubPlaceModel {
    id: String!
    name: String!
    primary_mdn: String!
  }

  enum PubNubAddressMediumType {
    PHONE_MOBILE
    PHONE_HOME
    PHONE_WORK
    PHONE_FAX
    PHONE_OTHER
    EMAIL
    SOCIAL_FACEBOOK
    SOCIAL_GOOGLE
    SOCIAL_X
  }

  enum PubNubAddressSourceEntityType {
    MANUAL_NUMA
    CRM_SALESFORCE
    DMS_CDK
    DMS_TEKION
    DMS_DEALERTRACK
    DMS_PBS
    DMS_REYNOLDS
    CNAM_CNAM
  }

  enum PubNubSpamStatus {
    UNKNOWN
    SUSPECTED_SPAM
    USER_REPORTED_SPAM
    USER_REPORTED_NOT_SPAM
  }

  enum PubNubParticipantDataConfidence {
    HIGH
    LOW
  }

  enum PubNubParticipantLoggedCallInteraction {
    UNKNOWN
    SPOKE
    MESSAGE
    NO_ANSWER
    WRONG_NUMBER
    SPAM
    OTHER
  }

  input PubNubConversationPreviewDataModel {
    preview: String!
    preview_format: String!
    preview_is_inbound: Boolean!
    preview_server_generated: Boolean!
    preview_mentions: [PubNubMention!]!
    preview_source: String!
    preview_display_name: String!
    sub_preview: String!
    sub_preview_format: String!
    sub_preview_mentions: [PubNubMention!]!
    sub_preview_display_name: String!
  }

  enum PubNubConversationOptInStatus {
    UNKNOWN
    CONVERSATIONAL_OPT_IN
    ONGOING_OPT_IN
    OPTED_OUT
  }

  enum PubNubLabelCategory {
    CALLBACK
    URGENT
    USER
    RO
  }

  input PubNubConversationLabelParticipantModel {
    id: String!
    display_name: String!
  }

  input PubNubLabelModel {
    id: String!
    place_id: String!
    category: PubNubLabelCategory!
    name: String!
    color: String!
    icon: String
    icon_type: String
    hidden: Boolean!
    expiration_hours: Int
    archived: Boolean!
  }
  input PubNubConversationLabels {
    id: String!
    created_on: DateTime!
    expires_at: DateTime
    hidden: Boolean!
    label: PubNubLabelModel!
    participant: PubNubConversationLabelParticipantModel!
  }

  input PubNubStageModel {
    id: String!
    name: String!
    place_id: String!
  }

  input PubNubStatusItemAssigneeModel {
    id: String!
    display_name: String
    place_id: String
  }

  input PubNubStatusItemParticipantModel {
    id: String!
    first_name: String!
    last_name: String!
    display_name: String!
    display_name_confidence: PubNubParticipantDataConfidence!
    address: String!
  }

  input PubNubLabelModelStub {
    id: String!
    category: PubNubLabelCategory!
    name: String!
  }
  input PubNubConversationLabelsStub {
    id: String!
    label: PubNubLabelModel!
  }

  input PubNubStatusItemConversationModel {
    id: String!
    state: ConversationState!
    inbox_id: String!
    last_updated: DateTime!
    waiting_since: DateTime
    escalated: DateTime
    conversation_labels: [PubNubConversationLabelsStub!]
  }

  input PubNubStatusItemModel {
    id: String!
    color: PubNubStatusItemColor!
    description: String!
    order_number: String!
    tag: String!
    eta: String
    created_on: DateTime!
    last_update_time: DateTime!
    stage: PubNubStageModel!
    status: PubNubStatusItemStatus!
    held_since: DateTime
    assignee: PubNubStatusItemAssigneeModel!
    participant: PubNubStatusItemParticipantModel
    conversation: PubNubStatusItemConversationModel
  }

  input PubNubStatusItemUpdateModel {
    id: String!
    color: PubNubStatusItemColor!
    description: String!
    order_number: String!
    tag: String!
    eta: String
    created_on: DateTime!
    last_update_time: DateTime!
    stage: PubNubStageModel!
    status: PubNubStatusItemStatus!
    held_since: DateTime
    assignee: PubNubStatusItemAssigneeModel!
    participant: PubNubStatusItemParticipantModel
    conversation: PubNubStatusItemConversationModel
    prev_status: PubNubStatusItemStatus!
    prev_color: PubNubStatusItemColor!
    prev_assignee_id: String!
  }

  input PubNubConversationLastChannel {
    id: String!
  }

  input PubNubConversationEngagement {
    id: String!
    summary: String
    summary_id: String
    summary_created_on: DateTime
  }

  input PubNubConversationModel {
    id: String!
    first_message_timestamp: DateTime!
    participant: PubNubParticipantModel!
    last_updated: DateTime
    created_on: DateTime!
    last_appointment: PubNubAppointmentModel
    last_message: PubNubMessageModel
    last_repair_order: PubNubRepairOrder
    state: ConversationState!
    server_generated_preview: Boolean!
    preview_data: PubNubConversationPreviewDataModel
    customer_waiting: Boolean!
    team_id: String!
    team: PubNubTeamModel
    language: String!
    unread_count: Int!
    assignee: PubNubUser
    top_intent: PubNubIntent
    inbox_id: String!
    am_i_mentioned: Boolean!
    is_mentioned_conversation: Boolean!
    last_mentioned_timestamp: DateTime
    escalated: DateTime
    place: PubNubPlaceModel!
    is_overdue: Boolean!
    waiting_since: DateTime
    snoozed_until: DateTime
    source: String!
    opt_in_status: PubNubConversationOptInStatus!
    conversation_labels: [PubNubConversationLabels!]
    last_channel: PubNubConversationLastChannel
    last_engagement: PubNubConversationEngagement
    has_recent_heat_case: Boolean!
  }

  input PubNubConversationTimestamp {
    id: String!
    timestamp: DateTime!
    last_updated: DateTime!
    waiting_since: DateTime
    inbox_id: String!
    assignee_id: String
    state: ConversationState!
    team: PubNubTeamModelStub
  }

  enum InboxSort {
    NEWEST
    WAIT_TIME
  }

  type ActiveInboxOptions {
    unreadOnly: Boolean
  }

  type ActiveInbox {
    inboxId: String!
    state: ConversationState!
    sort: InboxSort
    isOverdue: Boolean
    options: ActiveInboxOptions
  }

  enum PaymentsFilterType {
    REQUESTS
    SUCCESS
    HOLDS
    REFUNDS
    ATTENTION
    ALL
  }

  type ActivePaymentsCollection {
    status: PaymentsFilterType!
    collectionId: String!
  }

  extend type Query {
    activeAdvisorId: String
    activeInbox: ActiveInbox
    activePaymentsCollection: ActivePaymentsCollection
    outboundConferenceCalls(userId: String): [OutboundConferenceCallQL]
  }

  enum PubNubCurrency {
    USD
  }

  enum PubNubDialogflowOutcome {
    AGENT
    BOOKED
    HANGUP
    RECALL
    ERROR
  }
  enum PubNubDialogflowAgentReason {
    CUSTOMER_ASKED
    FAILED_RECOGNITION
    SCHEDULING_ROUND_TRIPS_EXCEEDED
    WEBHOOK_ERROR
    SERVICE_NOT_SUPPORTED
    NO_INPUT_RETRIES_EXCEEDED
    SERVICE_NOT_MAPPED
    WEBHOOK_TIMEOUT
  }

  enum PubNubSessionOutcome {
    BOOKED
    CANT_SERVICE
    HANGUP
    HUMAN
    LEAD
  }

  input PubNubDialogFlowBotDataModel {
    id: String!
    conversation_id: String!
    data: String!
    appointment_was_booked: Boolean!
    outcome: PubNubDialogflowOutcome
    agent_reason: PubNubDialogflowAgentReason
    agent_duration: Int!
    customer_in_dms: Boolean
  }

  input PubNubNumboxAgentSessionModel {
    id: String!
    slots: String!
    outcome: PubNubSessionOutcome
    feedback_submitted: Boolean
    appointment: PubNubAppointmentModel
  }

  enum PubNubPaymentRequestStatus {
    WAITING
    HOLD
    CANCELED
    REFUND
    PARTIAL_REFUND
    FAILED
    SUCCESS
  }

  enum PubNubPaymentRequestCancellationReason {
    ABANDONED
    DUPLICATE
    FRAUDULENT
    REQUESTED_BY_CUSTOMER
  }

  enum PubNubStatusItemColor {
    GREEN
    YELLOW
    RED
  }

  enum PubNubStatusItemStatus {
    ACTIVE
    HOLD
    DONE
  }

  input PubNubPaymentRequestPlaceModel {
    id: String!
    name: String!
  }

  input PubnubPaymentRequestParticipantModel {
    id: String!
    display_name: String!
  }

  input PubnubPaymentRequestCustomerParticipantModel {
    id: String!
    display_name: String!
    display_name_confidence: PubNubParticipantDataConfidence!
    address: String!
    source: String!
  }

  input PubNubPaymentRequestConversation {
    id: String!
  }

  enum PubNubPaymentRequestRefundType {
    FULL
    PARTIAL
  }

  enum PubNubPaymentRequestRefundStatus {
    PENDING
    SUCCEEDED
    FAILED
    CANCELED
  }

  enum PubNubPaymentRequestRefundReason {
    DUPLICATE
    FRAUDULENT
    REQUESTED
  }

  input PubNubPaymentRequestRefund {
    id: String!
    issuer: PubnubPaymentRequestParticipantModel!
    completed_on: DateTime!
    status: PubNubPaymentRequestRefundStatus!
    net_amount: Int!
    amount: Int!
    type: PubNubPaymentRequestRefundType!
    reason: PubNubPaymentRequestRefundReason!
    note: String
  }

  input PubNubPaymentRequestDispute {
    id: String!
    reason: String!
    status: String!
  }

  input PubNubPaymentRequestModel {
    id: String!
    amount: Int!
    net_amount: Int!
    total_refunded_amount: Int!
    currency: PubNubCurrency!
    amount_captured: Int!
    capture_method: String!
    description: String
    note: String
    order_id: String
    status: PubNubPaymentRequestStatus!
    created_on: DateTime!
    last_updated: DateTime!
    last_opened: DateTime
    charged: DateTime
    requested: DateTime
    billing_name: String
    billing_address: String
    billing_email: String
    billing_method: String
    billing_last_four_digits: String
    place: PubNubPaymentRequestPlaceModel!
    sender: PubnubPaymentRequestParticipantModel!
    customer: PubnubPaymentRequestCustomerParticipantModel!
    canceled_by: PubnubPaymentRequestParticipantModel
    canceled_at: DateTime
    cancellation_reason: PubNubPaymentRequestCancellationReason
    hold_expiration: DateTime
    application_id: String
    encoded_id: String!
    stripe_url: String
    conversation: PubNubPaymentRequestConversation
    failure_code: String
    failure_message: String
    outcome_seller_message: String
    outcome_type: String
    outcome_reason: String
    refunds: [PubNubPaymentRequestRefund!]
    disputes: [PubNubPaymentRequestDispute!]
    receipt_url: String
    attachment_url: String
    attachment_filename: String
  }

  enum PubNubStripeAccountType {
    CUSTOM
    EXPRESS
    STANDARD
    ACCOUNT_ONBOARDING
    ACCOUNT_UPDATE
  }

  input PubNubStripeIntegrationModel {
    id: String!
    place_id: String!
    stripe_account_id: String
    stripe_account_status: String
    stripe_account_type: PubNubStripeAccountType
  }

  enum PubNubTeamState {
    ACTIVE
    DELETED
  }

  enum PubNubTeamAssignmentStyle {
    MANUAL
    ROUND_ROBIN
  }

  input PubNubTeamModel {
    id: String!
    name: String!
    avatar: String!
    place_id: String!
  }

  input PubNubTeamModelStub {
    id: String!
    name: String!
  }

  input PubNubTeamSettingsModel {
    id: String!
    name: String!
    avatar: String!
    state: PubNubTeamState!
    assignment_style: PubNubTeamAssignmentStyle!
    place_id: String!
    user_count: Int!
  }

  enum PubNubVideoUploadStatus {
    PENDING
    FAILED
    READY
  }

  input PubNubVideoUploadLinkUploaderModel {
    id: String!
    display_name: String!
  }

  input PubNubVideoUploadLinkParticipantModel {
    id: String!
    display_name: String!
    display_name_confidence: PubNubParticipantDataConfidence!
    address: String!
    source: String!
  }

  input PubNubVideoUploadLinkModel {
    id: String!
    created_on: DateTime!
    status: PubNubVideoUploadStatus!
    place_id: String!
    upload_url: String!
    external_id: String!
    file_name: String
    file_size: Int
    note: String
    ready_to_stream: Boolean!
    duration: Float
    thumbnail_url: String
    preview_url: String
    playback_hls: String
    has_been_sent: Boolean!
    uploader: PubNubVideoUploadLinkUploaderModel
    participant: PubNubVideoUploadLinkParticipantModel
  }

  input PubNubOutboundConferenceCallCallerModel {
    id: String!
    display_name: String!
  }

  input PubNubOutboundConferenceCallRecordingModel {
    id: String!
    url: String
  }

  input PubNubOutboundConferenceCallModel {
    id: String!
    created_on: DateTime!
    caller: PubNubOutboundConferenceCallCallerModel!
    conversation_id: String!
    transcript: PubNubTranscriptModel!
    outbound_call_sid: String
    host_mdn: String!
    recipient_mdn: String!
    recipient_display_name: String!
    end_time: DateTime!
    start_time: DateTime!
    call_status: String!
    call_duration: Int!
    is_being_recorded: Boolean!
    has_call_assist: Boolean!
    call_recording: PubNubOutboundConferenceCallRecordingModel
  }

  extend type Mutations {
    PubNubArchiveConversation(conversation: PubNubConversationModel): Boolean
    PubNubArchiveConversations(
      inbox_id: String!
      conversations: [PubNubConversationTimestamp!]
    ): Boolean
    PubNubAppointmentEnriched(
      conversation_id: String!
      appointment: PubNubAppointmentModel!
    ): Boolean
    PubNubAssignConversation(conversation: PubNubConversationModel): Boolean
    PubNubAwayModeChanged(
      user_id: String!
      is_away: Boolean!
      is_schedule_enabled: Boolean!
      status_board_view: Boolean!
      next_shift_start: String
      next_shift_end: String
      can_manage_own_away_mode_settings: Boolean!
      is_unassigning_inbound_messages: Boolean!
      show_away_mode_banner_after: String
    ): Boolean
    PubNubDegradedInboxExperienceUpdated(
      account_id: String!
      place_id: String!
      has_degraded_inbox_experience: Boolean!
    ): Boolean
    PubNubEquityTradeOpportunityUpdated(
      conversation_id: String!
      equity_trade_opportunity: PubNubEquityTradeOpportunityModel!
    ): Boolean
    PubNubRestoreConversation(conversation: PubNubConversationModel): Boolean
    PubNubRestoreConversations(
      inbox_id: String!
      conversations: [PubNubConversationTimestamp!]
    ): Boolean
    PubNubMessageReceived(
      conversation: PubNubConversationModel
      message: PubNubMessageModel
    ): Boolean
    PubNubMessageSent(
      conversation: PubNubConversationModel
      message: PubNubMessageModel
    ): Boolean
    PubNubConversationDeleted(conversation: PubNubConversationModel): Boolean
    PubNubConversationLanguageReset(
      conversation: PubNubConversationModel
    ): Boolean
    PubNubLeaveConversations(conversation_ids: [String!]): Boolean
    PubNubConversationRecovered(conversation: PubNubConversationModel): Boolean
    PubNubDialogFlowBotDataUpdated(
      dialog_flow_bot_data: PubNubDialogFlowBotDataModel
    ): Boolean
    PubNubNumboxAgentSessionUpdated(
      numbox_agent_session: PubNubNumboxAgentSessionModel
    ): Boolean
    PubNubPlaceUpdated(place_id: String): Boolean
    PubNubPaymentRequestCreated(
      payment_request: PubNubPaymentRequestModel
    ): Boolean
    PubNubPaymentRequestUpdated(
      payment_request: PubNubPaymentRequestModel
    ): Boolean
    PubNubStripeIntegrationUpdated(
      stripe_integration: PubNubStripeIntegrationModel
    ): Boolean
    PubNubLabelCreated(label: PubNubLabelModel): Boolean
    PubNubLabelUpdated(label: PubNubLabelModel): Boolean
    PubNubTeamCreated(team: PubNubTeamSettingsModel): Boolean
    PubNubTeamUpdated(team: PubNubTeamSettingsModel): Boolean
    PubNubTeamDeleted(team_id: String!, place_id: String!): Boolean
    PubNubTranscriptUpdated(transcript: PubNubTranscriptModel): Boolean
    PubNubVideoUploadLinkUpdated(
      video_upload_link: PubNubVideoUploadLinkModel
    ): Boolean
    PubNubOutboundConferenceCallStatusUpdated(
      outbound_conference_call: PubNubOutboundConferenceCallModel
    ): Boolean
    PubNubOutboundProxyCallStatusUpdated(
      id: String!
      call_status: String!
      is_being_recorded: Boolean!
      start_time: String
    ): Boolean
    PubNubInboundCallStatusUpdated(
      call: PubNubInboundPhoneCallModel
      conversation_id: String!
    ): Boolean
    PubNubStatusItemCreated(statusItem: PubNubStatusItemModel): Boolean
    PubNubStatusItemUpdated(statusItem: PubNubStatusItemUpdateModel): Boolean
    PubNubStatusItemDeleted(statusItem: PubNubStatusItemModel): Boolean
    PubNubConversationEngagementUpdated(
      conversation: PubNubConversationModel
    ): Boolean
  }
`;
