import * as React from 'react';

import type { ApolloError } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import type { ConversationBuckets } from '@numbox/util';
import { bucketConversations } from '@numbox/util';

import { COLLECTION_NAMES } from '../types';
import { collapseEdges, concatEdges } from '../util';
import { GET_PAYMENT_REQUESTS } from './gql/getPaymentRequests.gql';

export type PaymentsFilterType =
  | 'REQUESTS'
  | 'SUCCESS'
  | 'HOLDS'
  | 'REFUNDS'
  | 'ATTENTION'
  | 'ALL';

type Props = {
  activeFilter: PaymentsFilterType;
  collectionId: string | null | undefined;
  userParticipantId: string | null | undefined;
  children: (arg0: {
    loading: boolean;
    paymentRequests?: ConversationBuckets;
    error?: ApolloError;
    fetchMore: () => Promise<void>;
    refetch: () => Promise<any>;
    hasMore: boolean;
  }) => React.ReactElement | null;
};

function buildStatusQueryFilter(activeFilter: PaymentsFilterType) {
  if (activeFilter === 'REQUESTS') {
    return { status: { equal: 'WAITING' } };
  }
  if (activeFilter === 'HOLDS') {
    return { status: { equal: 'HOLD' } };
  }
  if (activeFilter === 'ATTENTION') {
    return { status: { in: ['FAILED', 'DISPUTED'] } };
  }
  if (activeFilter === 'REFUNDS') {
    return { status: { in: ['REFUND', 'PARTIAL_REFUND'] } };
  }
  if (activeFilter === 'ALL') {
    return {};
  }
  return { status: { equal: activeFilter } };
}

export function buildGetPaymentsQueryFilter(
  activeFilter: PaymentsFilterType,
  collectionId: string | null | undefined,
  userParticipantId: string | null | undefined,
) {
  let queryFilter = {};

  const statusQueryFilter = buildStatusQueryFilter(activeFilter);
  if (!isEmpty(statusQueryFilter)) {
    queryFilter = { ...statusQueryFilter };
  }

  if (
    collectionId &&
    ![
      COLLECTION_NAMES.ALL,
      COLLECTION_NAMES.ASSIGNED,
      COLLECTION_NAMES.ADVISOR,
    ].includes(collectionId)
  ) {
    queryFilter = { ...queryFilter, placeId: { equal: collectionId } };
  }

  if (
    userParticipantId &&
    collectionId &&
    [COLLECTION_NAMES.ASSIGNED, COLLECTION_NAMES.ADVISOR].includes(collectionId)
  ) {
    queryFilter = { ...queryFilter, senderId: { equal: userParticipantId } };
  }

  return queryFilter;
}

export const GetPaymentRequests = ({
  activeFilter,
  collectionId,
  userParticipantId,
  children,
}: Props) => {
  const queryFilter = buildGetPaymentsQueryFilter(
    activeFilter,
    collectionId,
    userParticipantId,
  );

  return (
    <Query<getPaymentRequests, getPaymentRequestsVariables>
      query={GET_PAYMENT_REQUESTS}
      variables={{
        filter: queryFilter,
        status: activeFilter,
        placeId: get(queryFilter, 'placeId.equal'),
        senderId: get(queryFilter, 'senderId.equal'),
      }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error, fetchMore: fetchNextPage, refetch }) => {
        if (error) {
          return children({
            error,
            loading,
            fetchMore: () => new Promise(resolve => resolve()),
            refetch,
            hasMore: false,
          });
        }
        if (!data || !data.paymentRequests || !data.paymentRequests.edges) {
          return children({
            error,
            loading,
            fetchMore: () => new Promise(resolve => resolve()),
            refetch,
            hasMore: false,
          });
        }

        const { paymentRequests } = data;
        const { pageInfo } = paymentRequests;

        const edges = collapseEdges<
          getPaymentRequests$paymentRequests,
          getPaymentRequests$paymentRequests$edges$node
        >(paymentRequests);

        const fetchMore = async () => {
          if (!pageInfo.hasNextPage) {
            return;
          }
          try {
            await fetchNextPage({
              variables: {
                after: pageInfo.endCursor,
              },
              // @ts-expect-error FIXME: apollo upgrade signature change
              updateQuery: (prev, { fetchMoreResult }) => ({
                paymentRequests: concatEdges<
                  getPaymentRequests$paymentRequests,
                  'paymentRequests'
                >(prev, fetchMoreResult, 'paymentRequests'),
              }),
            });
          } catch {
            //  likely component unmounted:
            //  https://github.com/apollographql/apollo-client/issues/4114
          }
        };

        const buckets = bucketConversations(edges);

        return children({
          paymentRequests: buckets,
          fetchMore,
          refetch,
          loading,
          error,
          hasMore: pageInfo.hasNextPage,
        });
      }}
    </Query>
  );
};
