import { NumboxAgentSessionFragment } from '../../fragments/gql/numboxAgentSessionFragment.gql';
import type { ApolloContext } from '../../types';

export const convertNumboxAgentSession = (
  numbox_agent_session: PubNubNumboxAgentSessionModel,
): NumboxAgentSessionFields => {
  return {
    __typename: 'NumboxAgentSessionQL',
    id: numbox_agent_session.id,
    slots: numbox_agent_session.slots
      ? JSON.stringify(numbox_agent_session.slots)
      : null,
    outcome: numbox_agent_session.outcome || null,
    appointment: numbox_agent_session.appointment
      ? {
          __typename: 'AppointmentQL',
          ...numbox_agent_session.appointment,
        }
      : null,
  };
};

export const PubNubNumboxAgentSessionUpdated = (
  obj: any,
  args: { numbox_agent_session: PubNubNumboxAgentSessionModel },
  { client }: ApolloContext,
) => {
  client.writeFragment({
    id: `NumboxAgentSessionQL:${args.numbox_agent_session.id}`,
    fragment: NumboxAgentSessionFragment,
    fragmentName: 'NumboxAgentSessionFields',
    data: convertNumboxAgentSession(args.numbox_agent_session),
  });

  return null;
};
