import { gql } from '@apollo/client';

export const RepairOrderFragment = gql`
  fragment RepairOrderFields on RepairOrder {
    id
    repairOrderType
    status
    createdOn
    orderNumber
    tagNumber
    completedTime
    openedTime
    dmsStatus
    promisedTime
    transportationFlags
    lastDmsUpdate
    serviceAdvisorName
    serviceAdvisor {
      serviceAdvisorId
      name
    }
    vehicleMakeFriendly
    vehicleModelFriendly
    vehicleVin
    vehicleYear
  }
`;
