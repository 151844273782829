import { gql } from '@apollo/client';

export const GET_PLACES_USERS = gql`
  query placesUsers($placeId: String!) {
    placesUsers(placeId: $placeId) {
      id
      placeId
      receivesEscalations
      userId
      role
      user {
        email
        displayName
      }
    }
  }
`;

export const GET_PLACES_USERS_FOR_CALL_INTELLIGENCE = gql`
  query placesUsersForCallIntelligence($placeId: String!) {
    placesUsers(placeId: $placeId) {
      id
      placeId
      userId
      role
      user {
        displayName
      }
      callIntelligenceEnabled
    }
  }
`;
