import { withStyles as materialWithStyles } from '@mui/styles';
import * as React from 'react';
import { $Diff } from 'utility-types';

export type StyledComponentProps = {
  classes: Readonly<Record<string, string>>;
};

type Component<A> = React.ComponentType<A>;

export function pureWithStyle<Props extends {}, Comp extends Component<Props>>(
  name: string,
  stylesheet: Readonly<Record<string, any>>,
  comp: Comp,
  // @ts-expect-error ts-migrate(2344) FIXME: Type 'Props' does not satisfy the constraint 'Styl... Remove this comment to see the full error message
): Component<$Diff<Props, StyledComponentProps>> {
  comp.displayName = name;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Comp' is not assignable to param... Remove this comment to see the full error message
  return React.memo(materialWithStyles(stylesheet, { name })(comp)) as any;
}

export function withStyles<Props extends {}, Comp extends Component<Props>>(
  stylesheet: any,
  options?: any,
  // @ts-expect-error ts-migrate(2344) FIXME: Type 'Props' does not satisfy the constraint 'Styl... Remove this comment to see the full error message
): (arg0: Comp) => Component<$Diff<Props, StyledComponentProps>> {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ComponentType<(Omit<ConsistentWith<Component... Remove this comment to see the full error message
  return comp => materialWithStyles(stylesheet, options)(comp);
}
