import { useMutation, useQuery } from '@apollo/client';
import { gql } from '../gql-tag';

export const callIntelligenceSettingFieldsFragment = gql(`
  fragment CallIntelligenceSettingFields on CallIntelligenceSettingQL {
    id
    placeId
    disclosureContent
    renderedDisclosureContent
    playDisclosureSpeech
    permissions
    enabled
    disclosureSpeechUrl
    customDisclosureSpeechUrl
    customDisclosureSpeechOverride
    customDisclosureSpeech {
      data
      filename
    }
    seatLimit
    availableSeats
  }
`);

export const getCallIntelligenceSettingQuery = gql(`
  query GetCallIntelligenceSetting($placeId: String!) {
    callIntelligenceSetting(placeId: $placeId) {
      ...CallIntelligenceSettingFields
    }
    placeAllowsCustomAudio(placeId: $placeId)
  }
`);

export const getCallIntelligenceEnabledQuery = gql(`
  query GetCallIntelligenceEnabled($placeId: String!) {
    callIntelligenceSetting(placeId: $placeId) {
      enabled
      seatLimit
      availableSeats
    }
  }
`);

export const getPlacesCallIntelligenceEnabledQuery = gql(`
  query PlacesCallIntelligenceEnabled($placeIds: [String!]!) {
    places(queryFilter: { id: { in: $placeIds } }) {
      edges {
        node {
          id
          callIntelligenceSetting {
            enabled
            seatLimit
            availableSeats
          }
        }
      }
    }
  }
`);

export const updateCallIntelligenceSettingMutation = gql(`
  mutation UpdateCallIntelligenceSetting($input: UpdateCallIntelligenceSettingInput!) {
    updateCallIntelligenceSetting(input: $input) {
      callIntelligenceSetting {
        ...CallIntelligenceSettingFields
      }
    }
  }
`);

export const useGetCallIntelligenceSetting = (placeId: string) => {
  return useQuery(getCallIntelligenceSettingQuery, {
    variables: { placeId },
    fetchPolicy: 'cache-and-network',
  });
};

export const useGetCallIntelligenceEnabled = (
  placeId: string,
  skip: boolean = false,
) => {
  return useQuery(getCallIntelligenceEnabledQuery, {
    variables: { placeId },
    skip,
  });
};

export const useUpdateCallIntelligenceSetting = (placeId: string) => {
  const { data: cacheData } = useQuery(getCallIntelligenceSettingQuery, {
    variables: { placeId },
  });

  return useMutation(updateCallIntelligenceSettingMutation, {
    optimisticResponse: ({ input }) => {
      const seatLimit =
        input.seatLimit || cacheData?.callIntelligenceSetting?.seatLimit || 0;
      const id = cacheData?.callIntelligenceSetting?.id || '';
      const availableSeats =
        cacheData?.callIntelligenceSetting?.availableSeats || 0;

      return {
        updateCallIntelligenceSetting: {
          callIntelligenceSetting: {
            ...input,
            id,
            disclosureContent: input.disclosureContent || '',
            seatLimit,
            availableSeats,
          },
        },
      };
    },
  });
};

export const GET_CALL_INTELLIGENCE_RATING = gql(`
  query CallIntelligenceRating($transcriptAnalysisId: ID!, $userId: ID) {
    callIntelligenceRating(transcriptAnalysisId: $transcriptAnalysisId, userId: $userId) {
      transcriptAnalysisId
      value
      reason
      details
    }
  }
`);

export const useGetCallIntelligenceRating = (transcriptAnalysisId: string) => {
  return useQuery(GET_CALL_INTELLIGENCE_RATING, {
    variables: { transcriptAnalysisId },
  });
};

export enum RATING_SOURCE {
  MOBILE = 'mobile__transcript__rating_call_intelligence',
  INBOX = 'web__inbox__message_rating',
  NOTES_TAB = 'web__transcript__notes_tab',
  COACHING_TAB = 'web__transcript__coaching_tab',
}

export const RATE_CALL_INTELLIGENCE_MUTATION = gql(`
  mutation RateCallIntelligenceSummary($input: RateCallIntelligenceInput!) {
    rateCallIntelligenceSummary(input: $input) {
      transcriptAnalysisId
      value
      reason
      details
      source
    }
  }
`);

export const useRateCallIntelligenceSummary = () => {
  return useMutation(RATE_CALL_INTELLIGENCE_MUTATION, {
    optimisticResponse: ({ input }) => {
      return {
        rateCallIntelligenceSummary: {
          transcriptAnalysisId: input.transcriptAnalysisId,
          value: input.rating.value,
          reason: input.rating.reason,
          details: input.rating.details,
          source: input.rating.source,
        },
      };
    },
  });
};
