import { gql } from '@apollo/client';

import { AppointmentFragment } from './appointmentFragment.gql';
import { ConversationLabelFragment } from './labelFragment.gql';
import { MessageItemFragment } from './messageItem.gql';
import { UserItemFragment } from './userItem.gql';
import { ParticipantFragment } from './participant.gql';
import { TeamFragment } from './TeamFragment.gql';

export const ConversationItemStub = gql`
  fragment ConversationItemStub on ConversationQL {
    id
    state
    inboxId
    lastUpdated
    waitingSince
  }
`;

export const ConversationItemFragment = gql`
  fragment ConversationItemFields on ConversationQL {
    language
    place {
      id
      name
      primaryMdn
    }
    participant {
      ...ParticipantFields
    }
    previewData {
      preview
      previewFormat
      previewIsInbound
      previewServerGenerated
      previewDisplayName
      previewSource
      previewMentions {
        userId
        tag
      }
      subPreview
      subPreviewFormat
      subPreviewDisplayName
      subPreviewMentions {
        userId
        tag
      }
    }
    serverGeneratedPreview
    id
    inboxId
    lastUpdated
    waitingSince
    isOverdue
    snoozedUntil
    assignee {
      ...UserItemFields
    }
    state
    lastMessage {
      ...MessageItemFields
    }
    lastAppointment @include(if: $withLastAppointment) {
      ...AppointmentFields
    }
    lastChannel @include(if: $withLastChannel) {
      id
    }
    lastRepairOrder @include(if: $withLastRepairOrder) {
      ...RepairOrderFields
      statusItem {
        id
        status
        lastUpdateTime
        color
      }
    }
    lastEngagement {
      id
      summary
      summaryId
      mySummaryRating {
        value
        reason
        details
      }
    }
    amIMentioned
    isMentionedConversation
    lastMentionedTimestamp
    mentionedPreview
    mentionedDisplayName
    escalated
    source
    optInStatus
    conversationLabels {
      ...ConversationLabelFields
    }
    team {
      ...TeamFields
    }
    hasRecentHeatCase
  }
  ${AppointmentFragment}
  ${MessageItemFragment}
  ${UserItemFragment}
  ${ParticipantFragment}
  ${ConversationLabelFragment}
  ${TeamFragment}
`;
