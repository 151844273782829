import { Components, Theme } from '@mui/material';

export const MuiIcon: Components<Theme>['MuiIcon'] = {
  variants: [
    {
      props: { fontSize: 'xxsmall' },
      style: ({ theme }) => ({
        fontSize: `${theme.typography.body2.fontSize} !important`,
      }),
    },
    {
      props: { fontSize: 'xsmall' },
      style: ({ theme }) => ({
        fontSize: `${theme.typography.subtitle1.fontSize} !important`,
      }),
    },
    {
      props: { fontSize: 'xlarge' },
      style: ({ theme }) => ({
        fontSize: `${theme.typography.h1.fontSize} !important`,
      }),
    },
    {
      props: { fontSize: 'xxlarge' },
      style: {
        fontSize: `100px !important`,
      },
    },
  ],
  styleOverrides: {
    fontSizeInherit: () => ({
      fontSize: 'inherit !important',
    }),
    fontSizeSmall: ({ theme }) => ({
      fontSize: `${theme.typography.h6.fontSize} !important`,
    }),
  },
};
