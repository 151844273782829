export const SpecialAssigneeTypes = {
  Everyone: undefined,
  Unassigned: null,
} as const;

export type SpecialAssigneeType =
  // eslint-disable-next-line max-len
  (typeof SpecialAssigneeTypes)[keyof typeof SpecialAssigneeTypes];

export type AssignmentSelection =
  | {
      assignee: UserItemFields;
      type: 'User';
    }
  | {
      team: TeamFields;
      type: 'Team';
    }
  | SpecialAssigneeType;
