import { useMutation, useQuery } from '@apollo/client';
import { gql } from '../gql-tag';
import { useCallback } from 'react';

export const getCurrentAccountQuery = gql(`
  query getCurrentAccount {
    currentAccount {
      id
      createdOn
      name
      useNewNumber
      businessVertical
      billingIdentity {
        id
        brand
        billingOrderId
        paymentSource
      }
    }
  }
`);

export const deactivateAccountMutation = gql(`
  mutation DeactivateAccount($input: DeactivateAccountInput!) {
    deactivateAccount(input: $input) {
      account {
        id
        status
      }
    }
  }
`);

export const useCurrentAccount = () => {
  const { data, loading, error } = useQuery(getCurrentAccountQuery);
  const currentAccount = data?.currentAccount;
  return { currentAccount, loading, error };
};

export const useDeactivateAccount = () => {
  const [deactivateAccount] = useMutation(deactivateAccountMutation);

  return useCallback(
    (accountId: string) => {
      return deactivateAccount({ variables: { input: { accountId } } });
    },
    [deactivateAccount],
  );
};
