import { useQuery, useApolloClient, makeVar } from '@apollo/client';

import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import {
  getActiveInboxOptions,
  getActiveInboxOptionsGQL,
  ActiveInboxOptions,
} from '../types/activeInboxOptions';
import { GET_ACTIVE_INBOX } from '../queries/gql/getActiveInbox.gql';
import { GET_MY_INBOXES } from '../queries/gql/getMyInboxes.gql';
import { collapseEdges } from '../util/relay';
import {
  InboxQl,
  ConversationState,
  AssignmentFilterInput,
  AssignmentEntity,
} from '../graphql';
import { COLLECTION_NAMES } from '../types/collectionNames';
import { AssignmentSelection, SpecialAssigneeTypes } from '../types';

export const activeAssignmentSelectionVar = makeVar<AssignmentSelection>(
  SpecialAssigneeTypes.Everyone,
); // Uses Apollo's reactive variables to store the active assignment selection

export const buildAssignedToFilterForInbox = (
  inboxId: string | null | undefined,
  currentUserId: string | null | undefined,
  selectedAssignee: AssignmentSelection,
): AssignmentFilterInput | undefined => {
  // TODO(inbox-filters): When wiring up the assignee filter picker, add the appropriate argument(s)
  // to accept the selected assigned team / user.
  switch (inboxId) {
    case COLLECTION_NAMES.ADVISOR:
    case COLLECTION_NAMES.ASSIGNED:
      if (!currentUserId) {
        return undefined;
      }

      return {
        entityType: AssignmentEntity.User,
        entityId: currentUserId,
      };
    case COLLECTION_NAMES.UNASSIGNED:
      return {
        entityType: AssignmentEntity.Unassigned,
      };
    case COLLECTION_NAMES.ALL:
    default:
      if (selectedAssignee?.type === 'User') {
        return {
          entityType: AssignmentEntity.User,
          entityId: selectedAssignee.assignee.id,
        };
      }

      if (selectedAssignee?.type === 'Team') {
        return {
          entityType: AssignmentEntity.Team,
          entityId: selectedAssignee.team.id,
        };
      }

      if (selectedAssignee === SpecialAssigneeTypes.Unassigned) {
        return {
          entityType: AssignmentEntity.Unassigned,
        };
      }

      return undefined;
  }
};

export const useActiveInbox = () => {
  const { data, loading } = useQuery(GET_ACTIVE_INBOX);

  return {
    loading,
    activeInboxId: data?.activeInbox?.inboxId,
    state: data?.activeInbox?.state,
    sort: data?.activeInbox?.sort,
    options: getActiveInboxOptions(data?.activeInbox?.options),
  };
};

export const useMyInboxes = () => {
  const { data, loading, error } = useQuery(GET_MY_INBOXES);

  let inboxes = get(data, 'myInboxes', { edges: [] });
  const totalConversations = get(data, 'myInboxes.totalConversations', 0);
  const totalUnassignedConversations = get(
    data,
    'myInboxes.totalUnassigned',
    0,
  );

  const statusBoardView = get(data, 'myInboxes.statusBoardView', false);

  const conversationsAssignedToMe = get(
    data,
    'myInboxes.conversationsAssignedToMe',
    0,
  );

  const conversationMentions = get(data, 'myInboxes.conversationMentions', 0);

  const hideSharedInboxViews = get(
    data,
    'myInboxes.hideSharedInboxViews',
    false,
  );

  inboxes = sortBy(
    collapseEdges<myInboxes$myInboxes, myInboxes$myInboxes$edges$node>(inboxes),
    inbox => inbox.place.name,
  );

  return {
    inboxes,
    totalConversations,
    totalUnassignedConversations,
    conversationsAssignedToMe,
    conversationMentions,
    hideSharedInboxViews,
    statusBoardView,
    loading,
    error,
  };
};

export const useInboxSupportsOverdue = (inboxId?: string | null): boolean => {
  const { inboxes } = useMyInboxes();
  if (!inboxes) {
    return false;
  }

  if (!inboxId) {
    return false;
  }

  // If a place-specific inbox is selected (ID corresponds to a real inbox entity), then use the
  // corresponding place's escalation settings.
  // Otherwise, if in an inbox collection that may combine multiple place-specific inboxes, then
  // check if any of the place-specific inboxes available to user have escalations enabled and a
  // non-zero threshold set.
  switch (inboxId) {
    case COLLECTION_NAMES.ASSIGNED:
    case COLLECTION_NAMES.ADVISOR:
    case COLLECTION_NAMES.UNASSIGNED:
    case COLLECTION_NAMES.ALL:
      return inboxes
        .map((inbox: myInboxes$myInboxes$edges$node) => inbox?.place)
        .some(
          (place: myInboxes$myInboxes$edges$node$place) =>
            place &&
            place.escalationsEnabled &&
            (place.escalationsThreshold ?? 0) > 0,
        );
    default: {
      const place = inboxes.find((inbox: any) => inbox?.id === inboxId)?.place;
      if (!place) {
        return false;
      }

      return place.escalationsEnabled && (place.escalationsThreshold ?? 0) > 0;
    }
  }
};

export const usePlaceForInbox = (inboxId?: string | null) => {
  // TODO: This needs better return typing, but the myInboxes query is used in a lot of areas of
  // code that make for tricky refactoring.
  const { inboxes } = useMyInboxes();
  if (!inboxId || !inboxes) {
    return null;
  }

  // Special case for when the user can only see one place. "All" is essentially just that
  // specific place.
  if (inboxId === COLLECTION_NAMES.ALL && inboxes.length === 1) {
    return inboxes[0]?.place;
  }

  // Exclude known collections
  if (Object.values(COLLECTION_NAMES).includes(inboxId)) {
    return null;
  }

  const matchingInbox = inboxes.find((inbox: any) => inbox?.id === inboxId);

  return matchingInbox?.place;
};

type OnInboxSelect = (params: {
  inboxId: string;
  inboxName: string;
  state: ConversationState;
  options: ActiveInboxOptions;
  isOverdue: boolean;
}) => void;

export const useManageInboxes = (onInboxSelect?: OnInboxSelect) => {
  const {
    inboxes,
    totalConversations,
    totalUnassignedConversations,
    conversationsAssignedToMe,
    conversationMentions,
    hideSharedInboxViews,
    statusBoardView,
    loading,
    error,
  } = useMyInboxes();
  const client = useApolloClient();

  const selectInbox = (
    inboxId: string,
    state: ConversationState,
    sort: InboxSort = 'NEWEST',
    options?: ActiveInboxOptions,
    isOverdue?: boolean,
  ): void => {
    const activeInboxOptions = getActiveInboxOptions(options);
    client.writeQuery({
      query: GET_ACTIVE_INBOX,
      data: {
        activeInbox: {
          __typename: 'ActiveInbox',
          inboxId,
          sort,
          state,
          isOverdue: isOverdue ?? false,
          options: getActiveInboxOptionsGQL(activeInboxOptions),
        },
      },
    });

    if (onInboxSelect) {
      const activeInbox = inboxes.find(
        (inbox: InboxQl) => inbox.id === inboxId,
      );
      const inboxName = activeInbox ? activeInbox.place.name : '';

      onInboxSelect({
        inboxId,
        inboxName,
        state,
        options: activeInboxOptions,
        isOverdue: !!isOverdue,
      });
    }
  };

  const { activeInboxId, state, sort, options } = useActiveInbox();

  return {
    selectInbox,
    inboxes,
    activeInboxId,
    state,
    sort,
    options,
    totalConversations,
    totalUnassignedConversations,
    conversationsAssignedToMe,
    conversationMentions,
    hideSharedInboxViews,
    statusBoardView,
    loading,
    error,
  };
};
