import { gql } from '@apollo/client';

export const PUBNUB_NUMBOX_AGENT_SESSION_UPDATED = gql`
  mutation PubNubNumboxAgentSessionUpdated(
    $numbox_agent_session: PubNubNumboxAgentSessionModel!
  ) {
    PubNubNumboxAgentSessionUpdated(
      numbox_agent_session: $numbox_agent_session
    ) @client
  }
`;
