import { gql } from '../../gql-tag';

export const UPDATE_CALL_INTELLIGENCE_ENABLED = gql(`
  mutation UpdateCallIntelligenceEnabled($input: UpdateCallIntelligenceEnabledInput!) {
    updateCallIntelligenceEnabled(input: $input) {
      placesUser {
        id
        userId
        callIntelligenceEnabled
      }
    }
  }
`);
