import {
  all,
  put,
  takeEvery,
  takeLatest,
  call,
  take,
  fork,
  race,
} from 'redux-saga/effects';

import { delay } from 'redux-saga';
// @ts-expect-error ts-migrate(2614) FIXME: Module '"redux-saga"' has no exported member 'Saga... Remove this comment to see the full error message
import type { Saga } from 'redux-saga';

import { ReceivePushNotificationAction } from '~/modules/messages';

let isAnimating = true;

function* resetPageTitle(): Generator<any, void, any> {
  const reset = () => {
    document.title = 'Numa';
  };
  yield call(reset);
}

function* animate() {
  isAnimating = true;
  // get the current title that has the `(count)` in it or default to `Numa`
  let titleWithNuma = document.title.toLocaleLowerCase().includes('numa')
    ? document.title
    : 'Numa';
  while (isAnimating) {
    if (document.title === 'New Message') {
      document.title = titleWithNuma;
    } else {
      titleWithNuma = document.title;
      document.title = 'New Message';
    }

    yield call(delay, 1000);
  }
  document.title = titleWithNuma;
}

function* resetAnimationTimeout(): Generator<any, void, void> {
  yield delay(60000); // one minute
  yield put({ type: 'ANIMATION_TIMEOUT' });
}

function* onReceivePushNotification(
  action: ReceivePushNotificationAction,
): Generator<any, void, any> {
  const { shouldAnimateTitle } = action.payload;
  if (!shouldAnimateTitle) {
    return;
  }

  if (!isAnimating) {
    yield fork(animate);
  }

  const { newMessage } = yield race({
    foregrounded: take('TAB_FOREGROUNDED'),
    newMessage: take('RECEIVE_PUSH_NOTIFICATION'),
    timeout: take('ANIMATION_TIMEOUT'),
  });

  if (!newMessage) {
    // If it was a timeout or the browser brought to foreground, stop animating
    isAnimating = false;
  }
}

function* onEnterInbox(): Generator<any, void, void> {
  isAnimating = false;
  yield;
}

function* onLeaveInbox(): Generator<any, void, void> {
  isAnimating = false;
  yield;
}

function* watchPageTitleAnimation(): Saga<any> {
  yield all([
    takeEvery('LOGOUT_USER.SUCCESS', resetPageTitle),
    takeEvery('LEAVE_INBOX', onLeaveInbox),
    takeEvery('ENTER_INBOX', onEnterInbox),
    takeEvery('RECEIVE_PUSH_NOTIFICATION', onReceivePushNotification),
    takeLatest('RECEIVE_PUSH_NOTIFICATION', resetAnimationTimeout),
  ]);
}

export { watchPageTitleAnimation };
