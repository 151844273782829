import { gql } from '@apollo/client';
import { ConversationItemStub } from '../../fragments/gql/conversationItem.gql';

export const GET_FILTERED_CONVERSATIONS = gql`
  query filteredConversations(
    $first: Int
    $after: String
    $inboxId: String
    $state: String
    $assignedTo: AssignmentFilterInput
    $newerThan: ArrowField
    $sort: [ConversationModelSortEnum] = last_updated_desc
    $isOverdue: Boolean
  ) {
    conversations(
      first: $first
      after: $after
      sort: $sort
      queryFilter: {
        state: { equal: $state }
        inboxId: { equal: $inboxId }
        lastUpdated: { greaterThan: $newerThan }
        isOverdue: { equal: $isOverdue }
      }
      assignedTo: $assignedTo
    ) @connection(key: "conversations") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ConversationItemStub
        }
      }
    }
  }
  ${ConversationItemStub}
`;
