import { gql } from '@apollo/client';
import {
  APPOINTMENT_AGENT_SETTINGS_FIELDS,
  APPOINTMENT_AGENT_SETTINGS_PLACE_FIELDS,
  APPOINTMENT_AGENT_SETTINGS_ACTIVE_INTEGRATION_FIELDS,
} from '../../fragments/gql/appointmentFragment.gql';

export const GET_APPOINTMENT_AGENT_SETTINGS = gql`
  query getAppointmentAgentSettings(
    $id: String!
    $first: Int = 35
    $after: String
  ) {
    appointmentAgentSettings(id: $id) {
      __typename
      id
      name
      placeId
      ...AppointmentAgentSettingsFields
      activeIntegration {
        ...AppointmentAgentSettingsActiveIntegrationFields
      }
      latestXtimeSync {
        id
        endDate
      }
      place {
        ...AppointmentAgentSettingsPlaceFields
      }
    }
  }
  ${APPOINTMENT_AGENT_SETTINGS_ACTIVE_INTEGRATION_FIELDS}
  ${APPOINTMENT_AGENT_SETTINGS_FIELDS}
  ${APPOINTMENT_AGENT_SETTINGS_PLACE_FIELDS}
`;
