import { ApolloProvider } from '@apollo/client';

import React, { type FC, type PropsWithChildren } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Tuple, configureStore } from '@reduxjs/toolkit';
import { Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';

import createSagaMiddleware from 'redux-saga';

import { useTheme } from '@library/theme';

import * as Sentry from '@sentry/browser';

import { GraphQLClient } from '@numbox/apollo';

import { CoreThemeProvider } from '@library/themes/core';
import rootSaga from './sagas';
import { isUnsupportedBrowser } from './util/browser';

import reducers, { createReduxHistory, routerMiddleware } from './reducers';

import './index.css';
import locales from './locales';

import App from './components/App';
import { Auth0Wrapper } from './components/Auth0Wrapper';
import ScrollToTop from './components/ScrollToTop';
import { BrowserUnsupportedBanner } from './components/main/AppShell/Banners/BrowserUnsupportedBanner';
import { KeyboardListener } from './components/shared/KeyboardListener/KeyboardListener';
import { ApolloClientProvider } from './providers/ApolloClientProvider';

import './util/muiLicense';
import { useAppSelector } from './effects/redux';
import { Box } from './components/library/Box';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (p: any) => any;
  }
}

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(new Tuple(sagaMiddleware, routerMiddleware)),
});

sagaMiddleware.run(rootSaga);

const history = createReduxHistory(store);

addLocaleData([...en, ...es]);

const userLocale = navigator.language.split('-')[0];
// const userLocale = 'en';  // uncomment to force the app into a language

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'locale' implicitly has an 'any' type.
const getLanguage = locale => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (locales[locale]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return locales[locale];
  }
  return locales.en;
};

if (import.meta.env.PROD && !isUnsupportedBrowser) {
  Sentry.init({
    dsn: 'https://e7ad17ee7ce34d27aacc0472509bdfc7@o93523.ingest.sentry.io/210691',
    release: import.meta.env.VITE_CIRCLE_SHA1,
  });
}

const AppContainer = ({ children }: { children: React.ReactNode }) => {
  const themeMode = useAppSelector(state => state.theme.mode);

  return (
    <CoreThemeProvider mode={themeMode || 'light'}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {isUnsupportedBrowser && <BrowserUnsupportedBanner />}
        <div
          style={{
            position: 'relative',
            flexGrow: 1,
          }}
        >
          {children}
        </div>
      </div>
    </CoreThemeProvider>
  );
};

// Use this component to add CSS variables base on the theme here
const WithCSSVariables: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        '--mui-palette-divider': theme.palette.divider,
        color: theme.palette.text.primary,
      }}
    >
      {children}
    </Box>
  );
};

const domNode = document.getElementById('root');
const root = createRoot(domNode as Element);
root.render(
  <Provider store={store}>
    {/* @ts-expect-error children prop not completed */}
    <IntlProvider
      locale={userLocale}
      defaultFormats={getLanguage(userLocale).formats}
      messages={getLanguage(userLocale).messages}
    >
      <ApolloProvider client={GraphQLClient}>
        {/* @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
        <ApolloClientProvider>
          <KeyboardListener>
            {/* @ts-expect-error - Route type mismatch with react-router-dom */}
            <Router history={history}>
              <CompatRouter>
                <Auth0Wrapper client={GraphQLClient} store={store}>
                  <ScrollToTop>
                    <AppContainer>
                      <WithCSSVariables>
                        <App />
                      </WithCSSVariables>
                    </AppContainer>
                  </ScrollToTop>
                </Auth0Wrapper>
              </CompatRouter>
            </Router>
          </KeyboardListener>
        </ApolloClientProvider>
      </ApolloProvider>
    </IntlProvider>
  </Provider>,
);
