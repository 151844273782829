import { gql } from '@apollo/client';

export const NumboxAgentSessionFragment = gql`
  fragment NumboxAgentSessionFields on NumboxAgentSessionQL {
    id
    slots
    outcome
    appointment {
      id
      state
    }
  }
`;
