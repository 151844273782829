import type { ApolloContext } from '../../types';
import { updateActiveInbox } from '../../util';
import { ConversationItemStub } from '../../fragments/gql/conversationItem.gql';

export const PubNubArchiveConversations = async (
  obj: any,
  args: { inbox_id: string; conversations: Array<PubNubConversationTimestamp> },
  { client }: ApolloContext,
) => {
  await Promise.all(
    args.conversations.map(async conversation => {
      client.writeFragment({
        id: `ConversationQL:${conversation.id}`,
        fragment: ConversationItemStub,
        fragmentName: 'ConversationItemStub',
        data: {
          __typename: 'ConversationQL',
          id: conversation.id,
          state: conversation.state,
          inboxId: conversation.inbox_id,
          lastUpdated: conversation.last_updated,
          waitingSince: conversation.waiting_since,
        },
      });
    }),
  );

  updateActiveInbox(
    client,
    args.conversations.map(c => ({
      id: c.id,
      inboxId: c.inbox_id,
      lastUpdated: c.last_updated,
      state: c.state,
      assigneeId: c.assignee_id,
      waitingSince: c.waiting_since,
      team: c.team || null,
    })),
  );
};
