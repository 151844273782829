import { useFragment, useQuery } from '@apollo/client';
import { gql } from '../gql-tag';
import { useEffect } from 'react';

export const userMetricsFieldsFragment = gql(`
  fragment UserMetricsFields on UserMetrics {
    id
    openConversationsCount
    overdueConversationsCount
    lastMessageSentAt
    user {
      id
      displayName
      isAway
      nextShiftStart
      jobRole
    }
  }
`);

const userMetricsCollectionFragment = gql(`
  fragment UserMetricsCollection on UserMetricsConnection {
    edges {
      node {
        ...UserMetricsFields
      }
    }
  }
`);

export const placeMetricsFieldsFragment = gql(`
  fragment PlaceMetricsFields on PlaceMetrics {
    id
    openConversationsCount
    overdueConversationsCount
    unassignedConversationsCount
    place {
      id
      name
      inbox {
        id
      }
    }
  }
`);

const placeMetricsCollectionFragment = gql(`
  fragment PlaceMetricsCollection on PlaceMetricsConnection {
    edges {
      node {
        ...PlaceMetricsFields
      }
    }
  }
`);

export const getMyManagerDashboardQuery = gql(`
  query getMyManagerDashboard {
    myManagerDashboard {
      pollingIntervalMs
      userMetrics {
        ...UserMetricsCollection
      }
    }
  }
`);
export const getMyManagerDashboardQueryWithPlaceMetrics = gql(`
  query getMyManagerDashboardWithPlaceMetrics {
    myManagerDashboard {
      pollingIntervalMs
      userMetrics {
        ...UserMetricsCollection
      }
      placeMetrics {
        ...PlaceMetricsCollection
      }
    }
  }
`);

interface UseGetMyManagerDashboardOpts {
  /**
   * When enabled, the query will automatically poll for fresh results
   * based on server-provided polling interval.
   */
  enableAutoPolling?: boolean;
  includePlaceMetrics?: boolean;
}

export const useGetMyManagerDashboard = ({
  enableAutoPolling,
  includePlaceMetrics,
}: UseGetMyManagerDashboardOpts = {}) => {
  const gqlQuery = includePlaceMetrics
    ? getMyManagerDashboardQueryWithPlaceMetrics
    : getMyManagerDashboardQuery;
  const { startPolling, stopPolling, ...query } = useQuery(gqlQuery);

  const pollingInterval = query.data?.myManagerDashboard?.pollingIntervalMs;

  useEffect(() => {
    if (enableAutoPolling && pollingInterval) {
      startPolling(pollingInterval);
      return () => {
        stopPolling();
      };
    }
  }, [startPolling, stopPolling, pollingInterval, enableAutoPolling]);

  return query;
};

/**
 * Access loaded list of user metrics. Requires that dashboard metrics have been loaded,
 * such as by calling `useGetMyManagerDashboard`.
 */
export const useUserMetrics = () => {
  const { complete, data } = useFragment({
    fragment: userMetricsCollectionFragment,
    fragmentName: 'UserMetricsCollection',
    from: { __typename: 'UserMetricsConnection' },
  });

  if (!complete) {
    return null;
  }

  return data.edges
    .filter((edge): edge is NonNullable<typeof edge> => !!edge)
    .map(edge => edge.node)
    .filter((node): node is NonNullable<typeof node> => !!node);
};

export const usePlaceMetrics = () => {
  const { complete, data } = useFragment({
    fragment: placeMetricsCollectionFragment,
    fragmentName: 'PlaceMetricsCollection',
    from: { __typename: 'PlaceMetricsConnection' },
  });

  if (!complete) {
    return null;
  }

  return data.edges
    .filter((edge): edge is NonNullable<typeof edge> => !!edge)
    .map(edge => edge.node)
    .filter((node): node is NonNullable<typeof node> => !!node);
};
