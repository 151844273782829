import { gql } from '@apollo/client';
import { VoiceScriptFieldsFragment } from '../../fragments/gql/VoiceScriptFieldsFragment.gql';

export const GET_PLACE_DISRUPTION_SCRIPTS = gql`
  query getPlaceDisruptionScripts($placeId: String!) {
    place(id: $placeId) {
      id
      disruptions {
        id
        disruptionDepartmentVoiceScript {
          ...VoiceScriptFields
        }
        draftDisruptionDepartmentVoiceScript {
          ...VoiceScriptFields
        }
        holidayDepartmentVoiceScript {
          ...VoiceScriptFields
        }
        draftHolidayDepartmentVoiceScript {
          ...VoiceScriptFields
        }
      }
    }
  }
  ${VoiceScriptFieldsFragment}
`;
