import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

import { lazyLoad } from '../util/lazyLoad';

import RequireAuth from './RequireAuth';

import { DelayedLoadingIndicator } from './shared/DelayedLoadingIndicator';

const LoginWithToken = lazyLoad(
  () => import('./public/LoginWithToken'),
  './public/LoginWithToken',
);

const ForgotPassword = lazyLoad(
  () => import('./public/forms/ForgotPassword/ForgotPasswordPage'),
  './public/forms/ForgotPassword/ForgotPasswordPage',
);

const AcceptInvite = lazyLoad(
  () => import('./public/forms/AcceptInvite/AcceptInvitePage'),
  './public/forms/AcceptInvite/AcceptInvitePage',
);

const ResetPassword = lazyLoad(
  () => import('./public/forms/ResetPassword/ResetPasswordPage'),
  './public/forms/ResetPassword/ResetPasswordPage',
);

const NewLogin = lazyLoad(
  () => import('./public/forms/NewLogin/NewLogin'),
  './public/forms/NewLogin/NewLogin',
);

const Main = lazyLoad(() => import('./main'), './main');

const WithAuth = RequireAuth(Main);

export const MainAppRoutes = () => {
  return (
    <Suspense fallback={<DelayedLoadingIndicator />}>
      <Switch>
        <CompatRoute key="login" path="/login" component={NewLogin} />
        <CompatRoute key="newLogin" path="/new_login" component={NewLogin} />

        <CompatRoute
          key="pass"
          exact
          path="/forgotPassword"
          component={ForgotPassword}
        />

        <CompatRoute
          key="invite"
          exact
          path="/invite"
          component={AcceptInvite}
        />
        <CompatRoute
          key="reset"
          exact
          path="/reset"
          component={ResetPassword}
        />
        <CompatRoute
          key="token"
          exact
          path="/verify_account"
          component={LoginWithToken}
        />
        {/* @ts-expect-error - maintaining v5 compatibility - wrapped route */}
        <Route component={WithAuth} />
      </Switch>
    </Suspense>
  );
};
