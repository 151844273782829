import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  variants: [
    {
      props: { size: 'small' },
      style: ({ theme }) => ({
        fontSize: theme.typography.caption.fontSize,
        padding: 0,
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      boxShadow: 'none',
    }),
  },
};
