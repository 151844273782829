import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material';
import * as React from 'react';

export interface BoxProps
  extends Pick<
    MuiBoxProps,
    'children' | 'sx' | 'onClick' | 'id' | 'title' | 'tabIndex'
  > {
  component?: React.ElementType;
}

export const Box = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <MuiBox {...props} ref={ref} />
));
