import { gql } from '@apollo/client';

/**
 * this query is not typed properly, need to manually type when using
 * > when being used, need to cast `data` as `GetActiveInboxQuery`
 */
export const GET_ACTIVE_INBOX = gql`
  query getActiveInbox {
    activeInbox @client {
      inboxId
      state
      sort
      isOverdue
      options {
        unreadOnly
      }
    }
  }
`;
