import { gql } from '@apollo/client';
import { DialogFlowBotDataFragment } from './dialogFlowBotDataFragment.gql';

export const AppointmentFragment = gql`
  fragment AppointmentFields on AppointmentQL {
    id
    state
    createdOn
    expiresAt
    appointmentDate
    appointmentId
    numboxAgentSession {
      id
      slots
      outcome
    }
    externalServiceAdvisorName
    externalServiceAdvisorId
    enrichedFrom
    feedbackSubmitted
    dialogFlowBotData {
      ...DialogFlowBotDataFields
    }
  }
  ${DialogFlowBotDataFragment}
`;

export const APPOINTMENT_AGENT_SETTINGS_FIELDS = gql`
  fragment AppointmentAgentSettingsFields on AppointmentAgentSettingsQL {
    settings
    agentProfile
    optionalPrompts
    overallEmphasis
    numaInCallFlow
    webSchedulerReliability
    textingCanWeTextSpeech
    textingOnYesSpeechCanWeText
    textingOnNoSpeechCanWeText
    textingMessageToSend
    textingHangupRecovery
    renderedTextingMessageToSend
    renderedTextingHangupRecovery
    minimumLeadTimeInMinutes
    maximumBookingHorizonInDays
    enableHangupRecovery
  }
`;

export const APPOINTMENT_AGENT_SETTINGS_PLACE_FIELDS = gql`
  fragment AppointmentAgentSettingsPlaceFields on PlaceQL {
    id
    externalPlaceInfo {
      id
      linkGroups {
        groupLabel
        links {
          name
          tag
          url
        }
      }
    }
  }
`;

export const APPOINTMENT_AGENT_SETTINGS_ACTIVE_INTEGRATION_FIELDS = gql`
  fragment AppointmentAgentSettingsActiveIntegrationFields on ActiveIntegrationQL {
    id
    pendingOpcodeSync
    integrationId
    xtimeSyncs(first: $first, after: $after) {
      edges {
        node {
          id
          createdOn
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
