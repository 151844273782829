import { Components, Theme } from '@mui/material';

export const MuiModal: Components<Theme>['MuiModal'] = {
  defaultProps: {
    // this is so stories can target the MUI Modal element from within the
    // recommended `canvasElement`, instead of the default `body`
    ...(import.meta.env.STORYBOOK === 'true' && {
      container: document.getElementById('storybook-root'),
    }),
  },
};
