import { gql } from '../gql-tag';
import { useMutation, useQuery } from '@apollo/client';

export const upsertRepairOrderSmartStatusUpdateSettingsMutation = gql(`
  mutation upsertRepairOrderSmartStatusUpdateSettings(
    $input: RepairOrderSmartStatusUpdateSettingsInput!
  ) {
    upsertRepairOrderSmartStatusUpdateSettings(input: $input) {
      repairOrderSmartStatusUpdateSettings {
        id
        placeId
        detailLevel
        evaluationTimes {
          time
        }
        customInstructions
        createdOn
        lastUpdated
        isEnabled
      }
    }
  }
`);

export const getPlaceRepairOrderSmartStatusUpdateSettingsQuery = gql(`
  query getPlaceRepairOrderSmartStatusUpdateSettings($placeId: UUID!) {
    placeRepairOrderSmartStatusUpdateSettings(placeId: $placeId) {
      id
      placeId
      detailLevel
      evaluationTimes {
        time
      }
      customInstructions
      createdOn
      lastUpdated
      isEnabled
    }
  }
`);

export const generateRepairOrderAssistedStatusContentMutation = gql(`
  mutation GenerateRepairOrderAssistedStatusContent(
    $input: GenerateRepairOrderAssistedStatusContentInput!
  ) {
    generateRepairOrderAssistedStatusContent(input: $input) {
      statusContent
    }
  }
`);

export const buildRepairOrderAssistedStatusQuestionTreeMutation = gql(`
  mutation BuildRepairOrderAssistedStatusQuestionTree(
    $input: BuildRepairOrderAssistedStatusQuestionTreeInput!
  ) {
    buildRepairOrderAssistedStatusQuestionTree(input: $input) {
      questionTree {
        questions {
          id
          text
          answers {
            id
            text
            nextQuestionId
          }
          defaultAnswer {
            id
            text
            nextQuestionId
          }
        }
      }
    }
  }
`);

export const useGetPlaceRepairOrderSmartStatusUpdateSettings = (
  placeId: string,
) => {
  return useQuery(getPlaceRepairOrderSmartStatusUpdateSettingsQuery, {
    variables: { placeId },
  });
};

export const useUpsertRepairOrderSmartStatusUpdateSettings = () => {
  return useMutation(upsertRepairOrderSmartStatusUpdateSettingsMutation, {
    update: (cache, { data }) => {
      const placeId =
        data?.upsertRepairOrderSmartStatusUpdateSettings
          ?.repairOrderSmartStatusUpdateSettings?.placeId;
      if (placeId) {
        cache.writeQuery({
          query: getPlaceRepairOrderSmartStatusUpdateSettingsQuery,
          variables: { placeId },
          data: {
            placeRepairOrderSmartStatusUpdateSettings:
              data?.upsertRepairOrderSmartStatusUpdateSettings
                ?.repairOrderSmartStatusUpdateSettings,
          },
        });
      }
    },
  });
};

export const useGenerateRepairOrderAssistedStatusContent = () => {
  return useMutation(generateRepairOrderAssistedStatusContentMutation);
};

export const useBuildRepairOrderAssistedStatusQuestionTree = () => {
  return useMutation(buildRepairOrderAssistedStatusQuestionTreeMutation, {
    fetchPolicy: 'network-only',
  });
};
