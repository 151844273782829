import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import merge from 'lodash/merge';
import { ApolloClient, gql } from '@apollo/client';
import { safeRead } from './apollo';
import { GET_ACTIVE_INBOX } from '../queries/gql/getActiveInbox.gql';
import { GET_CURRENT_USER } from '../queries/gql/getCurrentUser.gql';
import {
  COLLECTION_NAMES,
  inboxIsCollection,
  SpecialAssigneeTypes,
} from '../types';
import type { ActiveInboxOptions, AssignmentSelection } from '../types';
import { removeEdgeFromConnection } from './relay';
import { ConversationItemStub } from '../fragments/gql/conversationItem.gql';
import { GET_MENTIONED_CONVERSATIONS } from '../queries/gql/getMentionedConversations.gql';
import { activeAssignmentSelectionVar } from '../features/inbox';
import { GET_USER_IDS_BY_TEAM_ID } from '../queries/gql/getUserIdsByTeamId.gql';

export const CONVERSATION_STUBS = gql`
  query conversations {
    conversations {
      edges {
        node {
          ...ConversationItemStub
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
  ${ConversationItemStub}
`;

const EVERYONE_TEAM_NAME = 'Everyone'; // This maps to the default team name

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'conversations' implicitly has an 'any' ... Remove this comment to see the full error message
const uniqueById = conversations => {
  const groupedBy: Record<string, Array<any>> = groupBy(
    conversations,
    'node.id',
  );

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'c' implicitly has an 'any' type.
  const mergedConversations = conversations.map(c =>
    merge({}, ...groupedBy[c.node.id].reverse()),
  );

  return uniqBy(mergedConversations, 'node.id');
};

export const assignmentMatches = ({
  currentUserIdsByTeamId,
  conversationAssigneeId,
  conversationAssignedTeamId,
  conversationAssignedTeamName,
  assignmentSelection,
}: {
  currentUserIdsByTeamId: string[] | null;
  conversationAssigneeId: string | null | undefined;
  conversationAssignedTeamId: string | null | undefined;
  conversationAssignedTeamName: string | null | undefined;
  assignmentSelection: AssignmentSelection;
}): boolean => {
  if (assignmentSelection === SpecialAssigneeTypes.Unassigned) {
    return (
      !conversationAssigneeId &&
      (!conversationAssignedTeamId ||
        conversationAssignedTeamName === EVERYONE_TEAM_NAME)
    );
  }
  if (assignmentSelection === SpecialAssigneeTypes.Everyone) {
    return true;
  }

  if (assignmentSelection.type === 'User') {
    return conversationAssigneeId === assignmentSelection.assignee.id;
  }

  if (assignmentSelection.type === 'Team') {
    return (
      (!conversationAssigneeId &&
        conversationAssignedTeamId === assignmentSelection.team.id) ||
      (!!conversationAssigneeId &&
        !!currentUserIdsByTeamId &&
        currentUserIdsByTeamId.includes(conversationAssigneeId))
    );
  }

  return true;
};

export const inboxMatches = ({
  activeInboxId,
  conversationInboxId,
  conversationAssigneeId,
  currentUserId,
  amIMentioned,
  isMentionedConversation,
  activeInboxOptions,
}: {
  activeInboxId: string;
  conversationInboxId: string | null | undefined;
  conversationAssigneeId: string | null | undefined;
  currentUserId: string | null | undefined;
  amIMentioned: boolean;
  isMentionedConversation: boolean;
  activeInboxOptions: ActiveInboxOptions;
}): boolean => {
  if (
    !inboxIsCollection(activeInboxId) &&
    activeInboxId === conversationInboxId
  ) {
    // Not a collection and inbox matches
    return true;
  }
  if (activeInboxId === COLLECTION_NAMES.MENTIONS && isMentionedConversation) {
    if (activeInboxOptions.unreadOnly) {
      return amIMentioned;
    }
    return true;
  }

  // If the conversation is in the assigned inbox and the assignee is the current user
  if (
    activeInboxId &&
    [COLLECTION_NAMES.ASSIGNED, COLLECTION_NAMES.ADVISOR].includes(
      activeInboxId,
    ) &&
    conversationAssigneeId &&
    conversationAssigneeId === currentUserId
  ) {
    return true;
  }

  // If the conversation is in the unassigned inbox and the conversation is not assigned
  if (
    activeInboxId === COLLECTION_NAMES.UNASSIGNED &&
    !conversationAssigneeId
  ) {
    return true;
  }

  // If the conversation is in the all inbox
  if (activeInboxId === COLLECTION_NAMES.ALL) {
    return true;
  }

  // If the conversation is in the status inbox
  if (activeInboxId === COLLECTION_NAMES.STATUS) {
    return true;
  }

  return false;
};

type Team = {
  id: string;
  name: string;
} | null;

export type UpdateActiveInboxConversations = Array<{
  id: string;
  inboxId?: string | null | undefined;
  assigneeId?: string | null | undefined;
  state: ConversationState | undefined;
  lastUpdated?: string | null | undefined;
  waitingSince?: string | null | undefined;
  amIMentioned?: boolean;
  isMentionedConversation?: boolean;
  lastMentionedTimestamp?: string;
  team: Team;
}>;

const getCurrentUserIdsByTeamId = (
  client: ApolloClient<any>,
  teamId: string | null | undefined,
): string[] | null => {
  if (!teamId) {
    return null;
  }
  const result = client.cache.readQuery({
    query: GET_USER_IDS_BY_TEAM_ID,
    variables: { teamId: teamId },
  });
  if (!result || !result.userIdsByTeamId) {
    return null;
  }
  return result.userIdsByTeamId;
};

export const updateActiveInbox = (
  client: ApolloClient<any>,
  apolloConversations: UpdateActiveInboxConversations,
) => {
  const currentUserQuery = safeRead(client, { query: GET_CURRENT_USER });
  const activeInboxQuery = safeRead(client, { query: GET_ACTIVE_INBOX });
  const assignmentSelection = activeAssignmentSelectionVar(); // Reads from reactive variable
  const currentConversationStubs = safeRead(client, {
    query: CONVERSATION_STUBS,
  });
  const currentMentionedConversationsStubs = safeRead(client, {
    query: GET_MENTIONED_CONVERSATIONS,
  });
  const teamId =
    assignmentSelection?.type === 'Team' ? assignmentSelection?.team?.id : null;
  const currentUserIdsByTeamId = getCurrentUserIdsByTeamId(client, teamId);

  let currentStubs = currentConversationStubs;

  // HACK 1: mentions use a separate cache entry,
  // so use those as stubs if on mentions inbox
  // see end of this function for equivalent cache-write behaviour
  if (activeInboxQuery?.activeInbox?.inboxId === COLLECTION_NAMES.MENTIONS) {
    currentStubs = {
      conversations: currentMentionedConversationsStubs?.mentionedConversations,
    };
  }

  if (!activeInboxQuery || !currentStubs || !currentStubs.conversations) {
    const reportError = console.error ? console.error : console.log;
    reportError(
      'updateActiveInbox aborting, activeInbox=',
      activeInboxQuery,
      'currentStubs=',
      currentStubs,
    );

    return;
  }

  const { activeInbox } = activeInboxQuery;

  let newStubs = {
    ...currentStubs,
  };

  for (const apolloConversation of apolloConversations) {
    newStubs = updateNewStubsForConversation(
      currentUserIdsByTeamId,
      activeInbox,
      assignmentSelection,
      apolloConversation,
      currentUserQuery,
      newStubs,
    );
  }

  // HACK 1 (continued): conversations and mentioned conversations are stored in separate
  // cache entries, so we need to update them both.
  // we don't selectively write to one or another (like stubs)
  // to ease testing, and this doesn't matter anyways since we
  // refetch the collections on inbox change
  if (activeInboxQuery?.activeInbox?.inboxId === COLLECTION_NAMES.MENTIONS) {
    client.writeQuery({
      query: GET_MENTIONED_CONVERSATIONS,
      data: {
        mentionedConversations: newStubs.conversations,
      },
    });
  }
  client.writeQuery({
    query: CONVERSATION_STUBS,
    data: newStubs,
  });
};

function updateNewStubsForConversation(
  currentUserIdsByTeamId: string[] | null,
  activeInbox: any,
  assignmentSelection: AssignmentSelection,
  apolloConversation: {
    id: string;
    inboxId?: string | null | undefined;
    assigneeId?: string | null | undefined;
    state: ConversationState | undefined;
    lastUpdated?: string | null | undefined;
    waitingSince?: string | null | undefined;
    isOverdue?: boolean;
    amIMentioned?: boolean;
    isMentionedConversation?: boolean;
    team?: Team;
    lastMentionedTimestamp?: string;
  },
  currentUserQuery: any,
  newStubs: any,
) {
  const action = chooseAction(
    currentUserIdsByTeamId,
    activeInbox,
    assignmentSelection,
    apolloConversation,
    currentUserQuery,
  );
  return updateNewStubsWithAction(action, newStubs, apolloConversation);
}

function updateNewStubsWithAction(
  action: string,
  newStubs: any,
  apolloConversation: {
    id: string;
    inboxId?: string | null | undefined;
    assigneeId?: string | null | undefined;
    state?: ConversationState | undefined;
    lastUpdated?: string | null | undefined;
    waitingSince?: string | null | undefined;
    isOverdue?: boolean;
    amIMentioned?: boolean;
    isMentionedConversation?: boolean;
    team?: Team;
    lastMentionedTimestamp?: string;
  },
) {
  if (action === 'REMOVE') {
    newStubs = {
      ...newStubs,
      conversations: {
        ...newStubs.conversations,
        ...removeEdgeFromConnection(
          newStubs.conversations,
          apolloConversation.id,
        ),
      },
    };
    // ADD the conversation
  } else {
    newStubs = {
      ...newStubs,
      conversations: {
        ...newStubs.conversations,
        edges: uniqueById([
          {
            __typename: 'ConversationEdge',
            node: {
              __typename: 'ConversationQL',
              id: apolloConversation.id,
              lastUpdated: apolloConversation.lastUpdated,
              state: apolloConversation.state,
              inboxId: apolloConversation.inboxId,
              waitingSince: apolloConversation.waitingSince,
              amIMentioned: apolloConversation.amIMentioned ?? false,
              lastMentionedTimestamp:
                apolloConversation.lastMentionedTimestamp ?? null,
            },
          },
          ...newStubs.conversations.edges,
        ]),
      },
    };
  }
  return newStubs;
}

function chooseAction(
  currentUserIdsByTeamId: string[] | null,
  activeInbox: any,
  assignmentSelection: AssignmentSelection | null | undefined,
  apolloConversation: {
    id: string;
    inboxId?: string | null | undefined;
    assigneeId?: string | null | undefined;
    state?: ConversationState | undefined;
    lastUpdated?: string | null | undefined;
    waitingSince?: string | null | undefined;
    isOverdue?: boolean;
    amIMentioned?: boolean;
    isMentionedConversation?: boolean;
    team?: Team;
    lastMentionedTimestamp?: string;
  },
  currentUserQuery: any,
) {
  let action = 'ADD';
  if (
    !inboxMatches({
      activeInboxId:
        activeInbox.inboxId === 'status'
          ? COLLECTION_NAMES.ASSIGNED
          : activeInbox.inboxId,
      conversationInboxId: apolloConversation.inboxId,
      conversationAssigneeId: apolloConversation.assigneeId,
      currentUserId: get(currentUserQuery, 'currentUser.id'),
      amIMentioned: apolloConversation.amIMentioned ?? false,
      isMentionedConversation:
        apolloConversation.isMentionedConversation ?? false,
      activeInboxOptions: activeInbox.options || {},
    }) ||
    !assignmentMatches({
      currentUserIdsByTeamId,
      conversationAssigneeId: apolloConversation.assigneeId,
      conversationAssignedTeamId: apolloConversation.team?.id,
      conversationAssignedTeamName: apolloConversation.team?.name,
      assignmentSelection: assignmentSelection,
    })
  ) {
    action = 'REMOVE';
  }
  // REMOVE the conversation if the state is different from the active inbox
  if (
    activeInbox.state !== apolloConversation.state &&
    apolloConversation.inboxId &&
    apolloConversation.inboxId !== COLLECTION_NAMES.MENTIONS
  ) {
    action = 'REMOVE';
  }
  if (apolloConversation.state === 'DELETED') {
    action = 'REMOVE';
  }
  if (activeInbox.isOverdue && !apolloConversation.isOverdue) {
    action = 'REMOVE';
  }
  return action;
}
